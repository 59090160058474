import axios from "axios";
import Cookies from "js-cookie";

const FileData = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
    env: `${process.env.VUE_APP_ENV}/api`,
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {
    getData(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = context.state.env;
        axios
          .get(`${env}/${data.path}`, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    postApi(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = context.state.env;
        let body = data.body;
        let formData = new FormData();
        for (let index = 0; index < data.body.length; index++) {
          if (
            Object.keys(data.body[index]) == "step" ||
            Object.keys(data.body[index]) == "klien_id" ||
            Object.keys(data.body[index]) == "tujuan_event" ||
            Object.keys(data.body[index]) == "tipe" ||
            Object.keys(data.body[index]) == "layanan_id" ||
            Object.keys(data.body[index]) == "timelines" ||
            Object.keys(data.body[index]) == "id" ||
            Object.keys(data.body[index]) == "nama" ||
            Object.keys(data.body[index]) == "telepon" ||
            Object.keys(data.body[index]) == "nip" ||
            Object.keys(data.body[index]) == "departemen" ||
            Object.keys(data.body[index]) == "jabatan" ||
            Object.keys(data.body[index]) == "no_sipp" ||
            Object.keys(data.body[index]) == "email" ||
            Object.keys(data.body[index]) == "alamat" ||
            Object.keys(data.body[index]) == "tgl_lahir" ||
            Object.keys(data.body[index]) == "jenis_kelamin" ||
            Object.keys(data.body[index]) == "no_karyawan" ||
            Object.keys(data.body[index]) == "tgl_masuk" ||
            Object.keys(data.body[index]) == "tgl_terminate" ||
            Object.keys(data.body[index]) == "status_karyawan" ||
            Object.keys(data.body[index]) == "total_tahun_kerja" ||
            Object.keys(data.body[index]) == "pendidikan_terakhir" ||
            Object.keys(data.body[index]) == "event_id" ||
            Object.keys(data.body[index]) == "step_id" ||
            Object.keys(data.body[index]) == "status" ||
            Object.keys(data.body[index]) == "prioritas" ||
            Object.keys(data.body[index]) == "to_email" ||
            Object.keys(data.body[index]) == "subject" ||
            Object.keys(data.body[index]) == "from_email" ||
            Object.keys(data.body[index]) == "message" ||
            Object.keys(data.body[index]) == "cc" ||
            Object.keys(data.body[index]) == "to_user_id" ||
            Object.keys(data.body[index]) == "replay"
          ) {
            formData.append(
              [Object.keys(data.body[index])],
              Object.values(data.body[index])
            );
          } else {
            formData.append(
              [Object.keys(data.body[index])],
              Object.values(data.body[index])[0]
            );
          }
        }
        body = formData;
        axios
          .post(`${env}/${data.path}`, body, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    restoreOfficer(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let config = context.state.config;
        axios
          .get(`${env}/api/sysadmin/restore/${data}`, config)
          .then((res) => {
            let data = res.data;
            if (data.code == 200) {
              resolve(data);
            }
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
};

export default FileData;
