<template>
  <v-navigation-drawer
    @input="hideSide($event)"
    color="white"
    app
    dark
    floating
    permanent
    v-model="expander"
    :width="drawer ? 256 : 130"
    style="
      padding: 0px;
      border-right-style: solid;
      border-right-width: 3px;
      border-right-color: #cfe1e1 !important;
    "
  >
    <div class="rounded_side">
      <sidebar-superadmin v-if="token" :drawer="drawer" />
      <sidebar-registrasi-client v-if="!token" :drawer="drawer" />
      <!-- <sidebar-admin :drawer="drawer" /> -->
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import sidebarSuperadmin from "./sidebarSuperadmin.vue";
import SidebarAdmin from "./sidebarAdmin.vue";
import SidebarRegistrasiClient from "./sidebarRegistrasiClient.vue";
export default {
  name: "desktopSidebar",

  components: {
    sidebarSuperadmin,
    SidebarAdmin,
    SidebarRegistrasiClient,
  },
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
      role: (state) => state.role,
      token: (state) => state.token,
    }),
    expander: {
      get: function () {
        return this.drawer;
      },
      set: function (val) {
        if (val) {
        }
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    hideSide(e) {
      this.$store.commit("MUTATE_MOBILE", false);
      if (e == false) {
        this.$store.commit("MUTATE_DRAWER", e);
      }
    },
  },
};
</script>

<style scoped>
.rounded_side {
  /* border-radius: 0 10px 10px 0; */
  height: 100%;
}
.v-list-item__icon {
  min-width: 10px !important;
  margin-left: 0 !important;
}
.v-list-item--link::before {
  background: none !important;
}
.custom_padding {
  padding-left: 50px !important;
}
.custom_padding_sub {
  padding-left: 10px !important;
}
.active_item_sidebar {
  background: #e6f4ec;
  border-radius: 10px;
  color: #6fbc92 !important;
}
.sub_dir {
  height: 100%;
  /* background: #e6f4ec; */
  /* padding: 5px;
  border-radius: 10px; */
}
.dir_item {
  padding: 5px;
  border-radius: 10px;
  background: #f6f6f6;
  cursor: pointer;
  height: 100%;
}
.dir_active {
  padding: 5px;
  border-radius: 10px;
  background: #e6f4ec;
  cursor: pointer;
  height: 100%;
}
.width_side {
  width: 256px !important;
}
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #edf1f0 !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
</style>
