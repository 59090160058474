<template>
  <div>
    <v-app-bar
      class="mt-2"
      v-if="this.$route.path == '/'"
      color="white"
      flat
      height="auto"
    >
      <v-row no-gutters align="center" justify="space-between">
        <div v-if="profile" class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown && isMobile"
            class="mr-2"
            @click="mutateSide"
            style="color: #568280"
          >
          </v-app-bar-nav-icon>
          <h4>Dashboard</h4>
          <div v-if="notification.length && $route.path !== '/notifications'">
            <v-menu
              offset-y
              nudge-bottom="10px"
              max-width="300px"
              min-width="300px"
              :close-on-content-click="false"
              v-model="notifyMenu"
              rounded="lg"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  color="red"
                  :content="arrIsReadNotif.unread"
                  :value="arrIsReadNotif.unread"
                  overlap
                >
                  <v-btn
                    @click="getNotif"
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon
                    class="ml-2"
                  >
                    <v-icon color="black">mdi-bell-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <div class="pa-4 white">
                <div class="d-flex align-center justify-space-between">
                  <h3>Notifikasi</h3>
                  <div
                    @click="markAllReaded"
                    class="d-flex align-center"
                    style="cursor: pointer"
                  >
                    <v-icon color="#106967" class="mr-1">mdi-check-all</v-icon>
                    <h5 class="not_bold" style="color: #106967">
                      Mark all as read
                    </h5>
                  </div>
                </div>
                <div class="my-3">
                  <v-btn
                    class="mr-2 text-capitalize"
                    rounded
                    depressed
                    :color="!Unread ? '#CFE1E1' : ''"
                    @click="(Unread = false), getNotif()"
                  >
                    <b :style="`color:${!Unread ? '#106967' : 'black'}`">
                      All
                    </b>
                  </v-btn>
                  <v-btn
                    :color="Unread? '#CFE1E1' : ''"
                    class="text-capitalize"
                    rounded
                    @click="(Unread = true), getNotif()"
                    depressed
                  >
                    <b :style="`color:${Unread ? '#106967' : 'black'}`">
                      Unread
                    </b>
                  </v-btn>
                </div>
                <content-notif
                  :isUnRead="Unread"
                  v-if="!loadNotif"
                  @get="getNotif"
                  @close="notifyMenu = false"
                />
                <div v-if="loadNotif">
                  <v-skeleton-loader
                    type="list-item-avatar"
                  ></v-skeleton-loader>
                </div>

                <div class="mb-2">
                  <b
                    class="small_txt"
                    style="cursor: pointer; color: #106967"
                    @click="toNotif"
                    >View All Notification</b
                  >
                </div>
              </div>
            </v-menu>
          </div>
          <v-badge
            v-if="!notification.length"
            color="red"
            :content="0"
            :value="0"
            overlap
          >
            <v-btn disabled small icon class="ml-2">
              <v-icon color="black">mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>

          <v-menu
            offset-y
            nudge-bottom="10px"
            min-width="200px"
            max-width="300px"
            rounded="lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="ml-4 d-flex align-center">
                <v-avatar dark class="mr-3" size="35">
                  <img
                    :src="
                      profile.data.profile.avatar
                        ? profile.data.profile.avatar
                        : ava
                    "
                    alt="avatar"
                    class="obj_fit"
                  />
                </v-avatar>
              </div>
            </template>
            <v-list class="px-3">
              <v-list-item>
                <v-list-item-title>
                  <div class="d-flex align-center pt-2">
                    <v-avatar class="mr-3">
                      <img
                        :src="
                          profile.data.profile.avatar
                            ? profile.data.profile.avatar
                            : ava
                        "
                        class="obj_fit"
                      />
                    </v-avatar>
                    <div>
                      <p class="ma-0 pa-0 txt_color_default">
                        <b class="text-capitalize">
                          {{
                            profile.data.profile.nama
                              ? profile.data.profile.nama.split(" ")[0]
                              : "-"
                          }}
                        </b>
                      </p>
                      <p class="ma-0 pa-0" style="color: #9ba0a3">
                        {{ profile.data.account.email }}
                      </p>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item>
              <v-divider class="my-2"></v-divider>
              <v-list-item :to="`/admin/profile`">
                <v-list-item-title class="txt_color_default">
                  <h4>Lihat Profile</h4>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>
                  <div class="d-flex align-center txt_color_default">
                    <v-icon class="mr-2" color="#052633"
                      >mdi-exit-to-app</v-icon
                    >
                    <h4>Logout</h4>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-col
          cols="12"
          :md="$vuetify.breakpoint.mdAndDown && isMobile ? '8' : '9'"
        >
          <v-text-field
            full-width
            hide-details
            solo
            dense
            flat
            outlined
            class="txt_input rounded-lg"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-app-bar>
    <desktop-sidebar v-if="!$vuetify.breakpoint.mdAndDown" />
    <mobile-sidebar v-if="$vuetify.breakpoint.mdAndDown" />
    <div class="side_active">
      <router-view
        :class="
          $vuetify.breakpoint.mdAndDown && isMobile
            ? 'bg_main full_mainMobile'
            : 'bg_main full_main'
        "
      ></router-view>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import DesktopSidebar from "./Sidebar/desktopSide.vue";
import ContentNotif from "./Notifikasi/contentNotif.vue";
import MobileSidebar from "./Sidebar/mobileSide.vue";
import {getFirestore, onSnapshot, collection, doc, deleteDoc, setDoc, addDoc, orderBy, query, where, or, and, getDocs, getDoc, updateDoc} from "firebase/firestore";
export default {
  name: "Home",
  components: { DesktopSidebar, MobileSidebar, ContentNotif },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      profile: (state) => state.profile,
      role: (state) => state.role,
      ava: (state) => state.ava,
      notification: (state) => state.notification,
      notificationUnRead: (state) => state.notificationUnRead,
      arrIsReadNotif: (state) => state.arrIsReadNotif,
    }),
  },
  created() {
    this.fetchProfile();
  },
  data() {
    return {
      toggler: true,
      notifyMenu: false,
      Unread: false,
      itemNotify: null,
      loadNotif: false,
    };
  },
  mounted() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.$store.commit("MUTATE_DRAWER", false);
      this.$store.commit("MUTATE_MOBILE", true);
      this.toggler = false;
    }
    let profile = setInterval(() => {
      if (this.profile) {
          this.getNotif();
        // fungsi web socket
        // window.Echo.channel(
        //   `notify${this.profile.data.profile.klien_id}`
        // ).listen("NotificationEvent", (event) => {
        //   if (event) {
            // this.getNotif();
        //     if (
        //       this.profile.data.account.id == event.data.to_user_id &&
        //       event.type == "send"
        //     ) {
        //       this.$store.dispatch("notify", {
        //         msg: event.data.message,
        //         type: "info",
        //       });
        //     }
        //   }
        // });
        // this.getNotif();
        // fungsi web socket
        clearInterval(profile);
      }
    }, 300);
  },
  methods: {
    logout() {
      Cookies.remove("token");
      Cookies.remove("role");
      this.$router.go("/auth/Login");
    },
    mutateSide() {
      if (this.toggler == this.drawer) {
        this.toggler = !this.toggler;
      }
      this.$store.commit("MUTATE_DRAWER", this.toggler);
    },
    fetchProfile() {
      let data = {
        path: "auth/profile",
      };
      this.$store.dispatch("fileData/getData", data).then((data) => {
        this.$store.commit("GET_PROFILE", data);
      });
    },
    getNotif() {
      this.loadNotif = true;
      
      setTimeout(
        function () { 
          this.loadNotif = false;
        }.bind(this)
      , 300);
    },
    toNotif() {
      this.notifyMenu = false;
      this.$router.push("/notifications");
    },
   async markAllReaded() {
      this.loading = true;
      const q = query(collection(getFirestore(), "notification"), where("recipient", "==", parseInt(this.profile.data.profile.user_id)), where('is_read', '==', false), orderBy('date','desc'));
      onSnapshot(q, async(querySnapshot) => {
        querySnapshot.docs.map(async(item) => {
          await updateDoc(doc(getFirestore(), "notification", item.data().id), {
            is_read:true,
          });
        })
      });
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.nav_round {
  border-radius: 10px 0 0 0 !important;
}
.min_top {
  margin-top: -5px;
}

@media (min-width: 396px) {
}

@media (max-width: 575px) {
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

@media (min-width: 960px) {
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media (min-width: 1440px) {
}
</style>
