<template>
  <div>
    <div class="d-flex flex-column align-center justify-center rounded" style="min-height: 250px; min-width: 350px; background-color: rgb(169, 169, 169);">
      <cropper v-if="inventory.src" ref="cropper" class="cropper" :src="inventory.src" />
    </div>
    <v-btn
      @click="cropImage"
      block
      color="#052633"
      dark
      class="text-capitalize mt-2"
      depressed
    >
      Jadikan Foto Profil
    </v-btn>
    <v-overlay :absolute="true" color="white" opacity=".5" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#006341"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { mapState } from "vuex";
export default {
  name: "imageCroper",
  components: { Cropper },
  props: ["inventory"],
  computed: {
    ...mapState({
      role: (state) => state.role,
      id: (state) => state.id,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async cropImage() {
      const result = this.$refs.cropper.getResult();
      let url = result.canvas.toDataURL(this.inventory.type);
      let file = null;
      await fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          file = new File([blob], "profilePhoto.png", { type: "image/png" });
        });
      this.updateProfile(file);
    },
    updateProfile(file) {
      this.loading = true;
      let body = [{ avatar: file }];
      let path = "auth/profile/update";

      let data = {
        path: path,
        body: body,
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          this.$emit("success");
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
  },
};
</script>

<style></style>
