<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="medium_txt bold ma-0 txt_color_default"><b>Nama</b></p>
          <v-text-field
            filled
            disabled
            placeholder="Silakan isi nama di sini"
            v-model="selected.nama"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-2">
          <p class="medium_txt bold ma-0 txt_color_default">
            <b>Id Karyawan</b>
          </p>
          <v-text-field
            placeholder="Silakan isi id karyawan di sini"
            filled
            disabled
            v-model="selected.id"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-2">
          <p class="medium_txt bold ma-0 txt_color_default">
            <b>Jam Masuk</b>
          </p>
          <v-menu
            ref="menu1"
            v-model="timeStart"
            :close-on-content-click="false"
            :return-value.sync="selected.absensi.wkt_absen"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                key="m"
                v-if="selected.absensi"
                v-model="selected.absensi.wkt_absen"
                class="rounded-lg mb-2"
                placeholder="Silakan isi jam masuk di sini"
                append-icon="mdi-clock-time-four-outline"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
                color="#0D47A1"
                dense
                outlined
              ></v-text-field>
            </template>
            <v-time-picker
              key="m"
              v-if="timeStart"
              v-model="selected.absensi.wkt_absen"
              full-width
              @click:minute="$refs.menu1.save(selected.absensi.wkt_absen)"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" class="mt-2">
          <p class="medium_txt bold ma-0 txt_color_default">
            <b>Jam Pulang</b>
          </p>
          <v-menu
            v-if="selected.absensi"
            ref="menu2"
            v-model="timeEnd"
            :close-on-content-click="false"
            :return-value.sync="selected.absensi.wkt_pulang"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selected.absensi.wkt_pulang"
                class="rounded-lg mb-2"
                placeholder="Silakan isi jam pulang di sini"
                append-icon="mdi-clock-time-four-outline"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
                color="#0D47A1"
                dense
                outlined
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="timeEnd"
              v-model="selected.absensi.wkt_pulang"
              full-width
              @click:minute="$refs.menu2.save(selected.absensi.wkt_pulang)"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" class="mt-2">
          <p class="medium_txt bold ma-0 txt_color_default">
            <b>Kehadiran</b>
          </p>
          <v-radio-group
            v-model="selected.absensi.keterangan"
            hide-details
            class="ma-0"
            dense
            row
          >
            <v-radio label="Hadir" color="#006341" value="hadir"></v-radio>
            <v-radio
              label="Tidak Hadir"
              color="#006341"
              value="tidak hadir"
            ></v-radio>
            <v-radio label="Telat" color="#006341" value="telat"></v-radio>
            <v-radio label="Izin" color="#006341" value="izin"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="mt-2">
          <p class="medium_txt bold ma-0 txt_color_default">
            <b>Keterangan</b>
          </p>
          <v-text-field
            placeholder="Silakan isi keterangan di sini"
            v-model="selected.absensi.note"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mt-4">
        <v-btn
          depressed
          class="text-capitalize bg_color1"
          @click="updateData"
          dark
          >Simpan</v-btn
        >
      </div>
    </div>

    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import {getFirestore, onSnapshot, collection, doc, setDoc, orderBy, query, where, or, getDoc, updateDoc} from "firebase/firestore";
import { mapState } from "vuex";
export default {
  name: "updateAttendence",
  props: ["selected"],
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
      timeStart: false,
      startTime: "",
      timeEnd: false,
      endTime: "",
      form: {
        wkt_absen: "",
        wkt_pulang: "",
        keterangan: "",
        note: "",
      },
      errors: {
        email: null,
        password: null,
      },
      idSubThreadPerChat:'',
    };
  },
  mounted() {},
  methods: {
    async updateData() {
      let success = false;
      this.loading = true;
      let body = {
        tgl_absen: this.selected.absensi.tgl_absen,
        wkt_absen: this.selected.absensi.wkt_pulang,
        wkt_pulang: this.selected.absensi.wkt_pulang,
        keterangan: this.selected.absensi.keterangan,
        note: this.selected.absensi.note,
      };

      let data = {
        body: body,
        path: `sysadmin/admin/absensi/update/${this.selected.absensi.id}`,
      };
      await this.$store
        .dispatch("officer/postApi", data)
        .then(async(data) => {
          this.loading = false;
          if (data.code == 200) {
            success = true;
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
            for (let i in this.form) {
              this.form[i] = "";
            }
          } else if (data.code == 201) {
            this.errors.email = data.data.errors.email;
            this.errors.password = data.data.errors.password;
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "error",
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
        if(success){
          await this.randomIdChat();
          setDoc(doc(getFirestore(), "notification/"+this.idSubThreadPerChat.toString()),{
            id: this.idSubThreadPerChat,
            sender: this.profile.data.profile.user_id,
            recipient: this.selected.user_id,
            text: this.selected.nama + ' mengubah absen Anda',
            text_html: '<b>' + this.selected.nama + ' mengubah absen Anda',
            url_page: '/admin/mk/client-attendence/attendence/'+this.profile.data.profile.klien_id,
            is_read: false,
            klien_id: this.selected.klien_id,
            date: Date.now(),
          });
          this.$emit("success");
        }
    },
    async randomIdChat(){
      let id = Math.random().toString(13).slice(2);
      
      let docRef = doc(getFirestore(), "notification/"+id.toString());
      let docSnap = await getDoc(docRef);
      
      do {
          id = Math.random().toString(13).slice(2);
          docRef = doc(getFirestore(), "notification/"+id.toString())
          docSnap = await getDoc(docRef);
      } while (docSnap.exists()){
        this.idSubThreadPerChat = id;
      }
    },
  },
};
</script>
<style scoped></style>
