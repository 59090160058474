<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt">
            <b>Kategori pengaduan</b>
          </p>
          <v-select
            class="rounded-lg mb-2"
            placeholder="Silakan pilih kategori pengaduan"
            outlined
            readonly
            filled
            hide-details
            :items="optComplaint"
            item-text="name"
            item-value="id"
            v-model="selected.pengaduan_kategori_id"
            dense
          ></v-select>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Pengaduan</b></p>
          <v-textarea
            placeholder="Silakan isi nama event disini"
            v-model="selected.konten"
            readonly
            filled
            rows="3"
            auto-grow
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Attachment</b></p>
          <v-text-field
            filled
            readonly
            placeholder="Silakan isi nama di sini"
            v-model="selected.attachment"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-lg mb-2 mb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Status</b></p>
          <v-radio-group
            v-model="selected.status"
            hide-details
            class="ma-0 mb-2"
            dense
            row
          >
            <v-radio
              label="Sudah Diperbaiki"
              color="#052633"
              :value="1"
            ></v-radio>
            <v-radio
              label="Belum Diperbaiki"
              color="#052633"
              :value="0"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex align-center justify-end mt-2">
      <v-btn
        outlined
        depressed
        color="#BD9028"
        class="text-capitalize mr-2"
        @click="$emit('close')"
        dark
        >Batal</v-btn
      >
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="updateStatus"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "createClient",
  props: ["selected"],
  data() {
    return {
      menuDate: false,
      loading: false,
      optComplaint: [],
    };
  },
  mounted() {
    this.fetchDataKategori();
    console.log(this.selected);
  },
  methods: {
    fetchDataKategori() {
      this.loading = true;
      let data = {
        path: `pengaduan/kategori`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          console.log(data);
          this.optComplaint = data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    updateStatus() {
      this.loading = true;
      let data = {
        body: {
          id: this.selected.id,
          status: this.selected.status,
        },
        path: `pengaduan/update/status`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            this.$emit("success");
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
  },
};
</script>
