<template>
  <div>
    <div class="pa-5">
      <div class="d-flex justify-center my-3">
        <v-card class="temp_dz" style="background-color: red; width: 650px">
          <v-overlay absolute color="white" dark opacity="1" :value="overlay">
            <div class="d-flex align-center" v-if="loading">
              <v-progress-circular
                indeterminate
                size="30"
                color="primary"
                class="mr-3"
              ></v-progress-circular>
              <p class="black--text ma-0">Uploading...</p>
            </div>
            <div
              class="black--text d-flex flex-column align-center"
              v-if="errMsg"
            >
              <div>
                <div v-if="errMsg.message.row" class="d-flex mb-3">
                  <div class="mr-3">
                    <v-icon color="black">mdi-information</v-icon>
                  </div>
                  <div>
                    <p class="ma-0">Error In Row {{ errMsg.message.row }}</p>
                    <p class="ma-0">{{ errMsg.message.message }}</p>
                  </div>
                </div>
                <div v-else class="d-flex align-center mb-3">
                  <div class="mr-3">
                    <v-icon color="black" class="">mdi-close-circle</v-icon>
                  </div>
                  <div>
                    {{ errMsg.message }}
                  </div>
                </div>
              </div>
              <v-btn @click="closeOverlay" depressed color="#03603A" rounded
                >Coba Lagi</v-btn
              >
            </div>
            <div v-if="resolve">
              <div class="d-flex justify-center black--text mb-5">
                <div>
                  <v-icon color="black" class="mr-2">mdi-check-circle</v-icon>
                  <b>
                    {{ resolve.message }}
                  </b>
                </div>
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  @click="closeOverlay"
                  class="mr-3 text-capitalize"
                  depressed
                  color="#03603A"
                  rounded
                  >Import Lagi</v-btn
                >
                <v-btn
                  @click="$emit('close')"
                  class="text-capitalize"
                  depressed
                  color="#03603A"
                  rounded
                  outlined
                  >Kembali ke List Expenditure</v-btn
                >
              </div>
            </div>
          </v-overlay>
          <vue-dropzone
            ref="myVueDropzones"
            id="dropzone"
            :useCustomSlot="true"
            :options="dropzoneOptions"
            @vdropzone-file-added="uploadFile"
            class="d-flex flex-column align-center justify-center"
          >
            <div>
              <h3 class="dropzone-custom-title">
                Drag and drop to upload file!
              </h3>
              <div class="subtitle">
                ...or click to select a file from your computer
              </div>
            </div>
          </vue-dropzone>
        </v-card>
      </div>
      <v-divider></v-divider>
      <div class="my-5">
        <p class="text-center grey--text ma-0">Don't have a template yet?</p>
        <div class="d-flex justify-center align-center">
          <h3 class="text-center grey--text">Download Template Here</h3>
        </div>
        <div class="d-flex justify-center align-center my-1">
          <v-btn
            dark
            rounded
            color="#052633"
            @click="downloadTemplate"
            class="d-flex align-center justify-center"
          >
            <v-icon class="mr-2">mdi-arrow-down</v-icon>
            <h5>Template</h5>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "importExpenditure",
  props: ["selected"],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      overlay: false,
      loading: false,
      errMsg: null,
      resolve: null,
      file: null,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        acceptedFiles:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        uploadMultiple: false,
        previewTemplate: this.template(),
      },
    };
  },
  mounted() {},
  methods: {
    closeOverlay() {
      this.overlay = false;
      this.resolve = null;
      this.$refs.myVueDropzones.removeAllFiles();
      this.errMsg = "";
    },
    // fungsi upload file
    uploadFile(e) {
      this.overlay = true;
      this.loading = true;
      let body = [{ file: e }];

      let data = {
        path: `sysadmin/expenditure/import`,
        body: body,
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          this.$emit("success");
          this.resolve = data;
          this.$refs.myVueDropzones.removeAllFiles();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.$refs.myVueDropzones.removeAllFiles();
          this.errMsg = e;
          this.loading = false;
        });
    },

    // download template
    downloadTemplate() {
      let data = {
        path: `sysadmin/expenditure/download-template`,
        body: "",
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          window.open(data.path);
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    template: function () {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-details" style="display:none;">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress" style="display:none;"><span class="dz-upload" data-dz-uploadprogress></span></div>
            </div>
        `;
    },
  },
};
</script>
<style scoped>
#dropzone {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  color: #777;
  border: 1px dashed;
  transition: background-color 0.2s linear;
  height: 250px;
  width: 100%;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #03603a;
}

.subtitle {
  color: #314b5f;
}
#dropzone .dz-preview {
  display: none;
  overflow: hidden;
}
.temp_dz {
  background: white !important;
  padding: 20px !important;
  border-radius: 20px !important;
  -webkit-box-shadow: 0px 10px 21px 11px rgba(3, 169, 244, 0.07) !important;
  -moz-box-shadow: 0px 10px 21px 11px rgba(3, 169, 244, 0.07) !important;
  box-shadow: 0px 10px 21px 11px rgba(3, 169, 244, 0.07) !important;
  height: max-content;
}
</style>
