<template>
  <div>
    <div class="px-1" style="height: 100vh">
      <v-row no-gutters align="start" justify="center" style="height: 8%">
        <v-col cols="8">
          <div
            class="pa-3 d-flex align-start justify-center"
            style="height: auto"
          >
            <v-img
              v-if="drawer && !isMobile"
              src="../../assets/icon/Logo.png"
              width="45px"
              height="30px"
              contain
            >
            </v-img>
            <v-img
              v-if="!drawer || isMobile"
              src="../../assets/icon/logo_small.png"
              width="50px"
              height="30px"
              contain
            >
            </v-img>
          </div>
        </v-col>
        <v-col cols="4" v-if="!isMobile">
          <div
            class="pa-3 d-flex align-start justify-center"
            style="height: 8vh"
          >
            <v-app-bar-nav-icon
              @click="mutateSide"
              style="color: #d0d1d2"
            ></v-app-bar-nav-icon>
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="start"
        :justify="isMobile ? 'center' : 'start'"
        style="height: 79%"
      >
        <v-col :cols="!drawer || isMobile ? 8 : 12">
          <div :class="drawer && !isMobile ? 'px-1' : ''">
            <v-list
              class="mb-10 px-2 rounded-lg"
              dense
              style="background-color: #cfe1e1"
            >
              <div class="mb-2 active_item_sidebar_expanded flex-column">
                <v-list-item
                  link
                  active-class="active_item_sidebar"
                  class="unactive_item_sidebar"
                >
                  <v-list-item-icon class="mr-0 pa-0">
                    <v-tooltip
                      nudge-right="15"
                      right
                      :disabled="drawer && !isMobile ? true : false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon color="black">mdi-cog-outline</v-icon>
                        </div>
                      </template>
                      <span>Operasional</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                    <v-list-item-title class="txt_Sidebar_Active_Sub"
                      >Operasional</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-expand-transition>
                  <div class="sub_dir">
                    <div class="pa-2">
                      <div
                        :class="
                          path ==
                          `/api/registration/customer/${this.$route.params.idRegistration}`
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon color="white"
                                  >mdi-clipboard-text-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Pendaftaran</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          class="txt_Sidebar_m_Active ml-2"
                        >
                          Pendaftaran
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
export default {
  name: "Sidebar",
  props: ["drawer"],
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.path = this.$route.path;
      let type = this.$route.params.type;
      if (this.path == "/") {
        for (let i in this.sideItem) {
          if (i == type) {
            this.sideItem[state] = true;
          } else {
            this.sideItem[i] = false;
          }
        }
      } else {
        this.changeState(type);
      }
    },
  },
  computed: {
    ...mapState({
      access: (state) => state.user.my_access,
      role: (state) => state.role,
      isMobile: (state) => state.isMobile,
    }),
    hider: {
      get: () => {
        return this.drawer;
      },
      set: () => {
        return this.drawer;
      },
    },
  },
  mounted() {
    this.path = this.$route.path;
    let type = this.$route.params.type;
    if (this.path == "/") {
      for (let i in this.sideItem) {
        if (i == type) {
          this.sideItem[state] = true;
        } else {
          this.sideItem[i] = false;
        }
      }
    } else {
      this.changeState(type);
    }
  },
  data() {
    return {
      sidebarMenu: true,
      change: false,
      path: "",
      otherMenu: false,
      sideItem: {
        db: false,
        ao: false,
        mo: false,
        mk: false,
        mku: false,
        co: false,
      },
    };
  },
  methods: {
    logout() {
      Cookies.remove("token");
      Cookies.remove("role");
      this.$router.go("/auth/Login");
    },
    mutateSide() {
      this.$store.commit("MUTATE_DRAWER", !this.drawer);
    },
    changeState(state, single) {
      for (let i in this.sideItem) {
        if (i == state) {
          this.sideItem[state] = true;
        } else {
          this.sideItem[i] = false;
        }
      }
      if (single) {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$store.commit("MUTATE_DRAWER", false);
        }
      }
    },
    redirectTo(path) {
      this.$router.push(path);
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$store.commit("MUTATE_DRAWER", false);
      }
    },
  },
};
</script>

<style>
.v-list-item__icon {
  min-width: 10px !important;
  margin-left: 0 !important;
}
.v-list-item--link::before {
  background: none !important;
}
.custom_padding {
  padding-left: 50px !important;
}
.custom_padding_sub {
  padding-left: 10px !important;
}
.active_item_sidebar {
  background-color: #052633 !important;
  border-radius: 8px;
  margin-bottom: 8px;
}
.unactive_item_sidebar {
  background: transparent;
  margin-bottom: 8px;
  justify-content: center;
}
.active_item_sidebar_expanded {
  background-color: white !important;
  border-radius: 8px;
  color: #fff !important;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}
.unactive_item_sidebar_expanded {
  background: transparent;
  margin-bottom: 8px;
  justify-content: center;
}
.bg-head {
  background: #e1e1e1;
}
.bg {
  background: #0e1614;
}
._avatar {
  object-fit: cover;
}

.text-small {
  font-size: small;
}
.sub_menu {
  margin-left: 35px;
}
.txt_Sidebar_nonActive {
  color: #9fc3c2;
  font-weight: normal !important;
}
.txt_Sidebar_Active {
  color: white;
  font-weight: bold !important;
}
.txt_Sidebar_nonActive_Sub {
  color: #9fc3c2;
  font-weight: normal !important;
}
.txt_Sidebar_Active_Sub {
  color: #052633;
  font-weight: bold !important;
}
.txt_Sidebar_m_Active {
  color: white;
  font-weight: bold !important;
  font-size: small;
}
.txt_Sidebar_m_Nonactive {
  color: #052633;
  font-weight: normal !important;
  font-size: small;
}
.sub_dir {
  height: 100%;
  /* background: #e6f4ec; */
  /* padding: 5px;
  border-radius: 10px; */
}
.dir_item {
  padding: 5px 5px 5px 30px;
  border-radius: 10px;
  background: white;
  cursor: pointer;
}
.dir_active {
  padding: 5px 5px 5px 30px;
  border-radius: 10px;
  background: #052633;
  cursor: pointer;
}
.dir_item_mobile {
  padding: 3px;
  border-radius: 10px;
  background: white;
  cursor: pointer;
}
.dir_active_mobile {
  padding: 3px;
  border-radius: 10px;
  background: #052633;
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
