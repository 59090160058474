import axios from "axios";
import Cookies from "js-cookie";
// CLIENT MANAGEMENT
const userClient = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
    env: `${process.env.VUE_APP_ENV}/api`,
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {
    getData(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = context.state.env;
        axios
          .get(`${env}/${data.path}`, config)
          .then((res) => {
            let data = res.data;
            if (data.code == 200) {
              resolve(data.data);
            }
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    postApi(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = context.state.env;
        axios
          .post(`${env}/${data.path}`, data.body, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    restoreClient(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let config = context.state.config;
        axios
          .get(`${env}/api/sysadmin/karyawan/${data}`, config)
          .then((res) => {
            let data = res.data;
            if (data.code == 200) {
              resolve(data);
            }
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
};

export default userClient;
