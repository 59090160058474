<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama</b></p>
          <v-select
            v-if="dataSet"
            placeholder="Silakan isi nama di sini"
            outlined
            hide-details
            :items="dataSet"
            item-text="nama"
            item-value="nama"
            v-model="field.nama"
            dense
            class="rounded-lg mb-2"
            @change="changeData(field.nama, 'nama')"
          ></v-select>
          <v-select
            v-if="!dataSet"
            placeholder="Silakan isi nama di sini"
            outlined
            hide-details
            item-text="nama"
            item-value="nama"
            class="rounded-lg mb-2"
            dense
          ></v-select>
        </v-col>
        <!-- <v-col cols="12">
          <p class="small_txt ma-0 black_txt">
            <b>Id Karyawan</b>
          </p>
          <v-select
            v-if="dataSet"
            placeholder="Silakan isi id karyawan di sini"
            outlined
            hide-details
            :items="dataSet"
            item-text="id"
            item-value="id"
            v-model="field.karyawan_id"
            dense
            class="rounded-lg mb-2"
            @change="changeData(field.karyawan_id, 'id')"
          ></v-select>
          <v-select
            v-if="!dataSet"
            placeholder="Silakan isi id karyawan di sini"
            outlined
            hide-details
            item-text="id"
            item-value="id"
            class="rounded-lg mb-2"
            dense
          ></v-select>
        </v-col> -->

        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Kategori Unit</b></p>
          <v-select
            placeholder="Silakan isi kategori unit di sini"
            outlined
            hide-details
            :items="optUnit"
            item-text="name"
            item-value="id"
            v-model="field.unit_id"
            dense
            class="rounded-lg mb-2"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Unit</b></p>
          <v-text-field
            v-model="field.unit"
            placeholder="Silakan isi unit di sini"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Gaji</b></p>
          <v-text-field
            v-model="field.gaji"
            placeholder="Silakan isi gaji di sini"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
            type="number"
          >
            <template v-slot:prepend-inner>
              <div class="d-flex align-center justify-center">
                <v-icon small> Rp. </v-icon>
              </div>
            </template></v-text-field
          >
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Tanggal Berlaku</b></p>
          <v-menu
            v-model="menuBerlaku"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="field.tgl_berlaku"
                prepend-inner-icon="mdi-calendar-range"
                readonly
                placeholder="Silakan isi tanggal berlaku di sini"
                hide-details
                dense
                v-on="on"
                outlined
                class="rounded-lg"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="field.tgl_berlaku"
              @input="closeDateMenu(field.tgl_berlaku, 'tgl_berlaku')"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Status Pembayaran</b></p>
          <v-radio-group
            v-model="field.status"
            hide-details
            class="ma-0"
            dense
            row
          >
            <v-radio label="Dibayar" color="#006341" value="2"></v-radio>
            <v-radio label="Belum" color="#006341" value="0"></v-radio>
            <v-radio label="Tertunda" color="#006341" value="1"></v-radio>
          </v-radio-group>
          <p
            v-if="field.status == 2"
            class="small_txt black_txt"
            style="color: red"
          >
            <b
              >*Pastikan Anda sudah membayar gaji sebelum Anda mengubah status
              pembayaran</b
            >
          </p>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Tanggal Pembayaran</b></p>
          <v-menu
            v-model="menuPayed"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="field.tgl_bayar"
                prepend-inner-icon="mdi-calendar-range"
                readonly
                placeholder="Silakan isi tanggal pembayaran di sini"
                hide-details
                dense
                v-on="on"
                outlined
                class="rounded-lg"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="field.tgl_bayar"
              @input="closeDateMenu(field.tgl_bayar, 'tgl_bayar')"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="createData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import {getFirestore, onSnapshot, collection, doc, setDoc, orderBy, query, where, or, getDoc, updateDoc} from "firebase/firestore";
import { mapState } from "vuex";
export default {
  name: "createSalary",
  props: ["optUnit"],
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
      dataSet: null,
      menuBerlaku: false,
      menuPayed: false,
      field: {
        nama: "",
        karyawan_id: "",
        unit: "",
        unit_id: "",
        gaji: "",
        tgl_berlaku: "",
        status: "",
        tgl_bayar: "",
      },
      idSubThreadPerChat:'',
      klien_id_recipient:1,
      user_id_recipient:1,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    closeDateMenu(item, type) {
      if (type == "tgl_bayar") {
        this.menuPayed = false;
        this.field.tgl_bayar == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.menuBerlaku = false;
        this.field.tgl_berlaku == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/karyawan/akun/list`,
      };
      this.$store
        .dispatch("userClient/getData", data)
        .then((data) => {
          console.log(data);
          this.dataSet = data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changeData(item, type) {
      for (let i = 0; i < this.dataSet.length; i++) {
        if (type == "nama") {
          if (this.dataSet[i].nama == item) {
            this.field.karyawan_id = this.dataSet[i].id;
            this.klien_id_recipient = this.dataSet[i].klien_id;
            this.user_id_recipient = this.dataSet[i].user_id;
            break;
          }
        } else {
          if (this.dataSet[i].id == item) {
            this.field.nama = this.dataSet[i].nama;
            this.klien_id_recipient = this.dataSet[i].klien_id;
            this.user_id_recipient = this.dataSet[i].user_id;
            break;
          }
        }
      }
    },
    async createData() {
      let isEmpty = false;
      let success = false;
      for (const item in this.field) {
        if (!this.field[item]) {
          if (item != "status" && item != "tgl_bayar") {
            this.$toast.error(`${item} tidak boleh kosong!`);
            isEmpty = true;
          }
        }
      }
      if (!isEmpty) {
        this.loading = true;
        let data = {
          body: {
            karyawan_id: this.field.karyawan_id,
            effective_date: this.field.tgl_berlaku,
            currency: "Rp",
            gaji: this.field.gaji,
            tanggal_bayar: this.field.tgl_bayar,
            karyawan_gaji_unit: this.field.unit,
            karyawan_gaji_unit_tipe_id: this.field.unit_id,
            status_bayar: this.field.status,
          },
          path: `sysadmin/admin/gaji/karyawan`,
        };
        await this.$store
          .dispatch("client/postApi", data)
          .then((data) => {
            this.loading = false;
            if (data.status != "success") {
              if (data.data) {
                var key = Object.keys(data.data.errors);
                var values = Object.values(data.data.errors);
                for (let i = 0; i < key.length; i++) {
                  for (let j = 0; j < values[i].length; j++) {
                    this.$toast.error(values[i][j]);
                  }
                }
              } else {
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
            } else {
              success = true;
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "success",
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            if (e.data) {
              var key = Object.keys(e.data.errors);
              var values = Object.values(e.data.errors);
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            } else {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
          });
      }
      if(success){
        await this.randomIdChat();
        let angka = parseInt(this.field.gaji);
        setDoc(doc(getFirestore(), "notification/"+this.idSubThreadPerChat.toString()),{
          id: this.idSubThreadPerChat,
          sender: this.profile.data.profile.user_id,
          recipient: this.user_id_recipient,
          text: this.profile.data.profile.nama + 'menambahkan gaji Anda sebesar ' + angka.toLocaleString('id-ID', {style: 'currency', currency: 'IDR',}),
          text_html: '<b>' + this.profile.data.profile.nama + '</b> menambahkan gaji Anda sebesar ' + angka.toLocaleString('id-ID', {style: 'currency', currency: 'IDR',}),
          url_page: '/admin/mk/salary',
          is_read: false,
          klien_id: this.klien_id_recipient,
          date: Date.now(),
        });
        for (let i in this.field) {
          this.field[i] = "";
        }
        this.$emit("success");
      }
    },
    async randomIdChat(){
      let id = Math.random().toString(13).slice(2);
      
      let docRef = doc(getFirestore(), "notification/"+id.toString());
      let docSnap = await getDoc(docRef);
      
      do {
          id = Math.random().toString(13).slice(2);
          docRef = doc(getFirestore(), "notification/"+id.toString())
          docSnap = await getDoc(docRef);
      } while (docSnap.exists()){
        this.idSubThreadPerChat = id;
      }
    },
  },
};
</script>
<style scoped></style>
