<template>
  <v-row justify="start">
    <v-dialog
      v-model="d_small"
      persistent
      scrollable
      max-width="300px"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card class="rounded_max hide_overflow" flat>
        <v-toolbar dense flat>
          <h3>{{ title }}</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2 pb-0">
          <v-container style="height: max-content" class="pt-1">
            <!-- DELETE FUNCTION -->
            <delete-function
              v-if="type == 'delete'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
              @refetch="$emit('refetch')"
            />
            <!-- ADD FUNCTION -->

            <add-function
              v-if="type == 'add_function'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
              @refetch="$emit('refetch')"
            />
            
            <!-- RESET EVENT -->
            <alert-reset
              v-if="type == 'd_reset'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
              @refetch="$emit('refetch')"
            />

            <!-- RESET EVENT FORM -->
            <alert-reset-form
              v-if="type == 'd_reset_form'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
              @refetch="$emit('refetch')"
            />
          </v-container> </v-card-text
      ></v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AddFunction from '../../components/addFunction/addFunction.vue';
import DeleteFunction from "../../components/deleteFunction/deleteFunction.vue";
import AlertResetForm from '../DashboardSuperadmin/alertResetForm.vue';
import AlertReset from "../Event/alertReset.vue";
export default {
  components: { DeleteFunction, AlertReset, AlertResetForm, AddFunction },
  props: ["d_small", "type", "title", "inventory"],
  methods: {
    closeAndFetch() {
      this.$emit("refetch");
      this.$emit("close");
    },
  },
};
</script>

<style></style>
