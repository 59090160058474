<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <div>
            <h4>Nama Instansi</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              :placeholder="`Silakan isi nama instansi disini`"
              v-model="selected.nama_instansi"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="mt-3">
            <h4>Jenis Instansi</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              :placeholder="`Silakan isi jenis instansi disini`"
              v-model="selected.jenis_instansi"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="mt-3 mr-2">
            <h4>Email</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              :placeholder="`Silakan isi email disini`"
              v-model="selected.email"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="mt-3">
            <h4>Telepon</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              :placeholder="`Silakan isi telepon disini`"
              v-model="selected.telepon"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="mt-3">
            <h4>Alamat</h4>
            <v-textarea
              rows="3"
              auto-grow
              class="rounded-lg"
              outlined
              :placeholder="`Silakan isi   disini`"
              v-model="selected.alamat"
              color="#0D47A1"
              dense
              hide-details
            ></v-textarea>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="mt-3">
            <h4>Website</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              :placeholder="`Silakan isi website disini`"
              v-model="selected.website"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="mt-3 mr-2">
            <h4>PIC</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              :placeholder="`Silakan isi pic disini`"
              v-model="selected.pic"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="mt-3">
            <h4>Kontak PIC</h4>
            <v-text-field
              class="rounded-lg"
              outlined
              :placeholder="`Silakan isi kontak pic disini`"
              v-model="selected.kontak_pic"
              color="#0D47A1"
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="updateData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "updatePartner",
  props: ["selected"],
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {},
  methods: {
    // update partner
    updateData() {
      this.loading = true;
      let body = {
        klien_id: this.profile.data.profile.klien_id,
        id: this.selected.id,
        nama_instansi: this.selected.nama_instansi,
        jenis_instansi: this.selected.jenis_instansi,
        email: this.selected.email,
        telepon: this.selected.telepon,
        alamat: this.selected.alamat,
        website: this.selected.website,
        pic: this.selected.pic,
        kontak_pic: this.selected.kontak_pic,
      };

      let data = {
        body: body,
        path: `sysadmin/instansi-partner/update`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            this.$emit("success");
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else if (data.code == 201) {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
  },
};
</script>
