<template>
  <v-row justify="start">
    <v-dialog
      v-model="d_medium"
      persistent
      scrollable
      max-width="450px"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card class="rounded_max hide_overflow" flat>
        <v-toolbar dense flat>
          <h3>{{ title }}</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider v-if="line"></v-divider>
        <v-card-text class="px-2 pb-0">
          <v-container style="height: max-content" class="pt-1">
            <!-- OFFICER -->
            <CreateOfficer
              v-if="type == 'c_officer'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />

            <post-officer
              v-if="type == 'c_ao'"
              @success="closeAndFetch"
              :inventory="inventory"
              @close="$emit('close')"
            />
            <!-- <UpdateOfficer
              v-if="type == 'u_officer'"
              @success="closeAndFetch"
              :selected="inventory"
            /> -->

            <!-- CLIENT -->
            <create-client
              v-if="type == 'c_client'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />
            <update-client
              v-if="type == 'u_client'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- EMPOLYEE -->
            <create-employee
              v-if="type == 'c_employee'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />

            <update-employee
              v-if="type == 'u_employee'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- ATTENDENCE -->
            <update-attendence
              v-if="type == 'u_attendence'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />
            <create-attendence
              v-if="type == 'c_attendence'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />
            <!-- SALARY -->
            <create-salary
              v-if="type == 'c_salary'"
              @success="closeAndFetch"
              :optUnit="inventory"
              @close="$emit('close')"
            />
            <update-salary
              v-if="type == 'u_salary'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />
            <create-salary-pay
              v-if="type == 'c_salary_pay'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />
            <update-salary-pay
              v-if="type == 'u_salary_pay'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- INVOICE MANUAL -->
            <send-email
              v-if="type == 'send_email'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- INVOICE AUTOMATIC -->
            <send-email-auto
              v-if="type == 'send_email_auto'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- SETUP REGISTRASI -->
            <generate-setup-pendaftaran
              v-if="type == 'c_setup_regis'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- CUSTOMER CLIENT -->
            <create-customer
              v-if="type == 'c_customer'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />
            <update-customer
              v-if="type == 'u_customer'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- EDIT SCHEDULE -->
            <update-status
              v-if="type == 'u_status'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- EDIT STATUS EVENT -->
            <update-status-event
              v-if="type == 'u_status_event'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- TASK -->
            <create-task
              v-if="type == 'c_task'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />
            <update-task
              v-if="type == 'u_task'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- EXPENDITURE -->
            <create-expenditure
              v-if="type == 'c_expenditure'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />
            <update-expenditure
              v-if="type == 'u_expenditure'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- Complaint -->
            <update-status-complaint
              v-if="type == 'u_complaint'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- PHOTOPROFILE -->
            <cropper
              :inventory="inventory"
              v-if="type == 'crop'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />

            <!-- INSTANSI PARTNER -->
            <create-partner
              v-if="type == 'c_partner'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />
            <update-partner
              v-if="type == 'u_partner'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- CUSTOMER SERVICES -->
            <create-chat
              v-if="type == 'add_chat'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- CREATE EVENT LAYANAN -->
            <create-event-layanan
              v-if="type == 'c_layanan'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <delete-event-layanan
              v-if="type == 'd_layanan'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />

            <!-- Log Activity -->
            <detail-activity
              v-if="type == 'v_activity'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CreateAttendence from "../Attendence/createAttendence.vue";
import UpdateAttendence from "../Attendence/updateAttendence.vue";
import createClient from "../Client/createClient.vue";
import UpdateClient from "../Client/updateClient.vue";
import CreateEmployee from "../Employee/createEmployee.vue";
import UpdateEmployee from "../Employee/updateEmployee.vue";
import CreateOfficer from "../Attendence/createAttendence.vue";
// import CreateOfficer from "../Officer/cOfficer.vue";
// import UpdateOfficer from "../Officer/uOfficer.vue";
import CreateSalary from "../Salary/createSalary.vue";
import CreateSalaryPay from "../Salary/createSalaryPay.vue";
import UpdateSalary from "../Salary/updateSalary.vue";
import PostOfficer from "../AccountOfficer/postOfficer.vue";
import UpdateSalaryPay from "../Salary/updateSalaryPay.vue";
import SendEmail from "../Invoice/sendEmail.vue";
import GenerateSetupPendaftaran from "../SetupRegistration/generateSetupPendaftaran.vue";
import SendEmailAuto from "../InvoiceAutomatic/sendEmailAuto.vue";
import UpdateStatus from "../Schedule/updateStatus.vue";
import UpdateStatusEvent from "../Event/updateStatusEvent.vue";
import CreateTask from "../TaskManagement/createTask.vue";
import UpdateTask from "../TaskManagement/updateTask.vue";
import CreateExpenditure from "../Expenditure/createExpenditure.vue";
import UpdateExpenditure from "../Expenditure/updateExpenditure.vue";
import UpdateStatusComplaint from "../Complaint/updateStatusComplaint.vue";
import CreateCustomer from "../Customer/createCustomer.vue";
import UpdateCustomer from "../Customer/updateCustomer.vue";
import Cropper from "../../components/cropper.vue";
import CreatePartner from "../InstansiPartner/createPartner.vue";
import UpdatePartner from "../InstansiPartner/updatePartner.vue";
import CreateChat from "../CustomerServices/createChat.vue";
import CreateEventLayanan from '../DashboardSuperadmin/createEventLayanan.vue';
import DeleteEventLayanan from '../DashboardSuperadmin/deleteEventLayanan.vue';
import DetailActivity from '../DashboardSuperadmin/detailActivity.vue';
export default {
  components: {
    createClient,
    CreateOfficer,
    // UpdateOfficer,
    UpdateClient,
    CreateEmployee,
    UpdateEmployee,
    UpdateAttendence,
    CreateSalary,
    CreateAttendence,
    UpdateSalary,
    CreateSalaryPay,
    PostOfficer,
    UpdateSalaryPay,
    SendEmail,
    GenerateSetupPendaftaran,
    SendEmailAuto,
    UpdateStatus,
    UpdateStatusEvent,
    CreateTask,
    UpdateTask,
    CreateExpenditure,
    UpdateExpenditure,
    UpdateStatusComplaint,
    CreateCustomer,
    UpdateCustomer,
    Cropper,
    CreatePartner,
    UpdatePartner,
    CreateChat,
    CreateEventLayanan,
    DeleteEventLayanan,
    DetailActivity,
  },
  props: ["d_medium", "type", "title", "inventory", "line"],
  methods: {
    closeAndFetch() {
      this.$emit("refetch");
      this.$emit("close");
    },
  },
};
</script>

<style></style>
