<template>
  <div v-if="selected">
    <p class="default_txt text-center">Anda yakin ingin menghapus?</p>
    <div class="d-flex justify-center mt-2 py-3">
      <v-btn
        outlined
        class="text-capitalize mr-2"
        color="#BD9028"
        @click="$emit('close')"
        dark
      >
        <div style="color: black">Kembali</div>
      </v-btn>
      <v-btn
        depressed
        class="text-capitalize mr-1 bg_color1"
        @click="deleteItem"
        dark
        >Hapus</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
    <v-overlay :absolute="true" opacity="1" color="white" :value="success">
      <div class="d-flex justify-center">
        <v-icon size="50" color="blue">mdi-delete-empty</v-icon>
      </div>
      <p class="ma-0 color_txt small_txt">
        {{ selected.deleteType }} berhasil terhapus
      </p>
    </v-overlay>
  </div>
</template>

<script>
import {getFirestore, doc, deleteDoc} from "firebase/firestore";
export default {
  name: "deleteFunction",
  props: ["selected"],

  data() {
    return {
      loading: false,
      success: false,
    };
  },
  methods: {
    async deleteItem() {
      this.loading = true;
      if(this.selected.deleteType != "Chat"){
        let data = {
          body: {
            id: this.selected.data.id,
          },
          path: this.selected.path,
        };
        if(this.selected.path!=''){
          this.$store
          .dispatch("officer/postApi", data)
          .then((data) => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.$emit("success");
            }, 500);
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch("notify", {
              msg: err.message,
              type: "error",
            });
          });
        }else{
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.$emit("success");
            }, 500);
            this.$store.dispatch("notify", {
              msg: 'Berhasil menghapus data',
              type: "success",
            });
        }
      }else{
        if(this.selected.data.id){
          const docRef = doc(getFirestore(), "chats/"+this.selected.path.id+"/messages/"+this.selected.data.id);
          await deleteDoc(docRef);

          this.success = true;
          this.$store.dispatch("notify", {
            msg: "Berhasil mengapus chat",
            type: "success",
          });

          setTimeout(() => {
            this.success = false;
            this.loading = false;
            this.$emit("success");
          }, 500);

        }else{
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: "Data not found!",
            type: "error",
          });
        }
      }
    },
  },
};
</script>
