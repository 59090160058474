<template>
  <div>
    <div v-if="!success">
      <div class="pa-5">
        <v-row no-gutters>
          <v-col cols="12">
            <p class="small_txt ma-0 black_txt"><b>Kirim ke</b></p>
            <v-text-field
              v-model="field.to_email"
              placeholder="Silakan isi email tujuan di sini"
              color="#0D47A1"
              dense
              hide-details
              outlined
              class="rounded-lg mb-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <p class="small_txt ma-0 black_txt"><b>Subject</b></p>
            <v-text-field
              v-model="field.subject"
              placeholder="Silakan isi subject tujuan di sini"
              color="#0D47A1"
              dense
              hide-details
              outlined
              class="rounded-lg mb-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <p class="small_txt ma-0 black_txt">
              <b>CC</b>
            </p>
            <v-text-field
              v-model="field.cc"
              placeholder="Silakan isi cc di sini"
              color="#0D47A1"
              dense
              hide-details
              outlined
              class="rounded-lg"
            ></v-text-field>
            <span class="small_txt mb-2">
              <p class="ma-0 x-small_txt">
                * Beri tanda <b>";"</b> sebelum email ke dua.<br />
              </p>
              ex: Alamat email 1; Alamat email 2; dst
            </span>
          </v-col>
          <v-col cols="12">
            <p class="small_txt ma-0 black_txt"><b>Kirim dari</b></p>
            <v-text-field
              v-model="field.form_email"
              placeholder="Silakan isi email anda di sini"
              color="#0D47A1"
              dense
              hide-details
              outlined
              class="rounded-lg mb-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <p class="small_txt ma-0 black_txt"><b>Pesan</b></p>
            <v-textarea
              v-model="field.message"
              placeholder="Silakan isi pesan di sini"
              rows="4"
              auto-grow
              color="#0D47A1"
              dense
              hide-details
              outlined
              class="rounded-lg mb-2"
              type="number"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12">
            <p class="small_txt ma-0 black_txt"><b>Lampiran</b></p>
            <v-file-input
              v-model="field.file"
              placeholder="Silakan link lampiran di sini"
              outlined
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              dense
              hide-details
              class="rounded-lg"
            ></v-file-input>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex justify-end mt-2">
        <v-btn
          depressed
          class="text-capitalize mr-2"
          @click="$emit('close')"
          dark
          outlined
          color="#BD9028"
          >Batal
        </v-btn>
        <v-btn
          depressed
          class="text-capitalize bg_color1"
          @click="createSendEmail"
          dark
          >Kirim
        </v-btn>
      </div>
    </div>
    <div v-if="success">
      <div
        class="pa-5 d-flex align-center justify-center flex-column text-center"
      >
        <img
          class="pr-10 pt-4"
          src="../../assets/img/success Kirim Email.png"
          height="200px"
          style="object-fit: contain"
        />
        <h3 class="black_txt">
          Selamat Anda telah selesai membuat Layanan Psikologi.
        </h3>
        <h5>Anda akan dialihkan langsung dalam {{ time }} detik ...</h5>
      </div>
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "sendEmail",
  props: ["selected"],
  data() {
    return {
      loading: false,
      success: false,
      time: 6,
      field: {
        to_email: "",
        form_email: "",
        subject: "",
        cc: "",
        message: "",
        file: null,
      },
    };
  },
  mounted() {},
  methods: {
    createSendEmail() {
      let array_cc = this.field.cc.split(";");
      if (array_cc == "") {
        array_cc = [];
      }
      this.loading = true;
      let body = [
        {
          to_email: this.field.to_email,
        },
        {
          subject: this.field.subject,
        },
        {
          from_email: this.field.form_email,
        },
        {
          message: this.field.message,
        },
        {
          cc: JSON.stringify(array_cc),
        },
      ];
      if (this.field.file) {
        body.push({
          file: this.field.file,
        });
      }
      let data = {
        body: body,
        path: `sysadmin/admin/invoice/send-email`,
      };
      this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            this.success = true;
            for (let i in this.field) {
              this.field[i] = "";
            }
            this.field.file = null;
            this.$emit("success");
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
  },
};
</script>

<style></style>
