<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama</b></p>
          <v-text-field
            filled
            disabled
            :value="selected.nama"
            placeholder="Silakan isi nama di sini"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Gaji</b></p>
          <v-text-field
            filled
            disabled
            :value="selected.gaji"
            placeholder="Silakan isi gaji di sini"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
            type="number"
          >
            <template v-slot:prepend-inner>
              <div class="d-flex align-center justify-center">
                <v-icon small> Rp. </v-icon>
              </div>
            </template></v-text-field
          >
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Tanggal Berlaku</b></p>
          <v-menu
            v-model="menuBerlaku"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selected.effective_date"
                prepend-inner-icon="mdi-calendar-range"
                readonly
                placeholder="Silakan isi tanggal berlaku di sini"
                hide-details
                dense
                v-on="on"
                outlined
                class="rounded-lg"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selected.effective_date"
              @input="closeDateMenu(selected.effective_date, 'tgl_berlaku')"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="createData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "updateSalaryPay",
  props: ["selected"],
  data() {
    return {
      role: "",
      loading: false,
      menuBerlaku: false,
      gaji: "",
      form: {
        id: "",
        idx: "",
      },
    };
  },
  mounted() {},
  methods: {
    closeDateMenu(item, type) {
      this.menuBerlaku = false;
      this.selected.tanggal_bayar == ""
        ? this.$date(item).format("dddd, MMMM Do YYYY")
        : "";
    },
    createData() {
      this.loading = true;
      let data = {
        body: {
          karyawan_id: this.selected[this.form.idx].karyawan_id,
          effective_date: this.selected.effective_date,
        },
        path: `sysadmin/admin/gaji/karyawan/bayar`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.status != "success") {
            if (data.data) {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            } else {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
          } else {
            this.$emit("success");
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          if (e.data) {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          } else {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
<style scoped></style>
