<template>
  <div>
    <div v-if="selected" class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <h4 class="txt_color_default">Event</h4>

          <v-menu
            transition="slide-y-transition"
            bottom
            max-height="200px"
            rounded="lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                depressed
                block
                outlined
                v-bind="attrs"
                v-on="on"
                class="rounded-lg d-flex align-center justify-start text-capitalize"
              >
                <div class="small_txt" style="color: gray">
                  {{
                    idx != null
                      ? selected.event[idx].layanan +
                        " - " +
                        selected.event[idx].customer
                      : "Silakan pilih event di sini"
                  }}
                </div>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="px-2"
                v-for="(item, n) in selected.event"
                :key="n"
              >
                <v-btn
                  block
                  depressed
                  :color="field.event_id == item.id ? '#E5E5E5' : 'white'"
                  @click="changeEventId(item.id)"
                  class="d-flex align-center justify-start text-capitalize"
                >
                  {{ item.layanan + " - " + item.customer }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <h4 class="txt_color_default">Tugas</h4>
          <v-select
            v-if="idx != null"
            placeholder="Silakan pilih tugas di sini"
            outlined
            hide-details
            :items="selected.event[idx].timeline"
            item-text="step"
            item-value="step_id"
            dense
            v-model="field.step_id"
            class="rounded-lg mb-2"
          ></v-select>
          <v-select
            filled
            disabled
            v-if="idx == null"
            placeholder="Silakan pilih tugas di sini"
            outlined
            hide-details
            dense
            class="rounded-lg mb-2"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <div class="pr-2">
            <h4 class="txt_color_default">Status</h4>
            <v-select
              placeholder="Status tugas"
              outlined
              hide-details
              :items="optStatus"
              item-text="text"
              item-value="val"
              dense
              v-model="field.status"
              class="rounded-lg mb-2"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="pl-2">
            <h4 class="txt_color_default">Prioritas</h4>
            <v-select
              placeholder="Prioritas tugas"
              outlined
              hide-details
              :items="optPrioritas"
              item-text="text"
              item-value="val"
              dense
              v-model="field.prioritas"
              class="rounded-lg mb-2"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="pr-2">
            <h4 class="txt_color_default">Attachment</h4>
            <v-file-input
              v-model="field.file"
              placeholder="Silakan tambah file disini"
              outlined
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              dense
              hide-details
              class="rounded-lg"
            ></v-file-input>
          </div>
        </v-col>
        <v-col cols="12">
          <div v-if="dataKaryawan && !loading_karyawan">
            <h4 class="mt-3 txt_color_default">Pilih Karyawan</h4>
            <v-data-table
              v-if="selected"
              v-model="field.karyawan"
              :headers="headers"
              :items="dataKaryawan.data"
              item-key="nama"
              show-select
              class="elevation-0"
              hide-default-footer
              :single-select="false"
            >
            </v-data-table>
            <div class="text-center pa-3" v-if="selected">
              <v-pagination
                color="#052633"
                v-model="page"
                @input="fetchData"
                :length="dataKaryawan.last_page"
              ></v-pagination>
            </div>
          </div>
          <div v-if="loading_karyawan">
            <v-skeleton-loader
              class="pa-2 elevation-0"
              type="table-tbody"
            ></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex align-center justify-end mt-4">
        <v-btn
          outlined
          depressed
          color="#BD9028"
          class="text-capitalize mr-2"
          @click="$emit('close')"
          dark
        >
          <h4>Batal</h4>
        </v-btn>
        <v-btn
          depressed
          class="text-capitalize bg_default"
          @click="createData"
          dark
        >
          <h4 v-if="!loading">Simpan</h4>
          <h4 v-if="loading">Loading</h4>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {getFirestore, onSnapshot, collection, doc, setDoc, orderBy, query, where, or, getDoc, updateDoc} from "firebase/firestore";
export default {
  name: "createTask",
  props: ["selected"],
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      loadingSave: false,
      loading: false,
      loading_karyawan: true,
      find: "",
      dataSet: null,
      field: {
        event_id: "",
        step_id: "",
        status: "",
        prioritas: "",
        file: null,
        karyawan: [],
      },
      dataKaryawan: null,
      idx: null,
      page: 1,
      limit: 5,
      headers: [
        { text: "Nama", value: "nama" },
        { text: "Posisi", value: "jabatan" },
        { text: "Tipe", value: "status_karyawan" },
      ],
      optStatus: [
        {
          text: "Done",
          val: "done",
        },
        {
          text: "Ongoing",
          val: "ongoing",
        },
        {
          text: "Overdue",
          val: "overdue",
        },
      ],
      optPrioritas: [
        {
          text: "Low",
          val: "low",
        },
        {
          text: "Mid",
          val: "mid",
        },
        {
          text: "High",
          val: "high",
        },
      ],
      idSubThreadPerChat:'',
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    changeEventId(id) {
      this.field.event_id = id;
      this.checkIdx(id);
    },
    checkIdx(idx) {
      for (let i = 0; i < this.selected.event.length; i++) {
        if (this.selected.event[i].id == idx) {
          this.idx = i;
          break;
        }
      }
    },
    async createData() {
      this.loading = true;
      let success = false;
      let body = [
        {
          event_id: this.field.event_id,
        },
        {
          klien_id: this.profile.data.account.klien.id,
        },
        {
          step_id: this.field.step_id,
        },
        {
          status: this.field.status,
        },
        {
          prioritas: this.field.prioritas,
        },
        {
          karyawan: JSON.stringify(this.field.karyawan),
        },
      ];

      if (this.field.file) {
        body.push({
          file: this.field.file,
        });
      }
      let data = {
        body: body,
        path: `sysadmin/task-management/create`,
      };
      await this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            success = true;
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });

        if(success){
          let step = '';
          if(this.selected.event[this.idx]){
            step = this.selected.event[this.idx].timeline.find(d => d.step_id === this.field.step_id);
          }
          this.field.karyawan.forEach(async (item) => {
            await this.randomIdChat();
            await setDoc(doc(getFirestore(), "notification/"+this.idSubThreadPerChat.toString()),{
              id: this.idSubThreadPerChat,
              sender: this.profile.data.profile.user_id,
              recipient: item.user_id,
              text: this.profile.data.profile.nama + ' menambah Anda di event ' + this.selected.event[this.idx].layanan + ' - ' + this.selected.event[this.idx].customer + ' dibagian ' + step.step,
              text_html: '<b>' + this.profile.data.profile.nama + '</b> menambah Anda di event ' + this.selected.event[this.idx].layanan + ' - ' + this.selected.event[this.idx].customer + ' dibagian ' + step.step,
              $url_page: '/admin/mk/task-management/',
              is_read: false,
              klien_id: item.klien_id,
              date: Date.now(),
            });
          });

          for (let i in this.field) {
            this.field[i] = "";
          }
          this.field.karyawan = [];
          this.field.file = null;
          this.$emit("success");
        }
    },
    async randomIdChat(){
      let id = Math.random().toString(13).slice(2);
      
      let docRef = doc(getFirestore(), "notification/"+id.toString());
      let docSnap = await getDoc(docRef);
      
      do {
          id = Math.random().toString(13).slice(2);
          docRef = doc(getFirestore(), "notification/"+id.toString())
          docSnap = await getDoc(docRef);
      } while (docSnap.exists()){
        this.idSubThreadPerChat = id;
      }
    },
    fetchData() {
      this.loading_karyawan = true;
      let data = {
        path: `sysadmin/karyawan/akun/list?filter[klien_id]=${this.profile.data.account.klien.id}&page[size]=${this.limit}&page[number]=${this.page}&filter[nama]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          console.log(data);
          this.dataKaryawan = data;
          this.loading_karyawan = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading_karyawan = false;
        });
    },
    remove(idx) {
      this.field.karyawan.splice(idx, 1);
    },
  },
};
</script>

<style></style>
