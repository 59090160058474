<template>
  <div>
    <div v-if="isUnRead">
      <div v-if="notificationUnRead.length">
        <div
          v-for="(item, i) in notificationUnRead"
          :key="i">
          <div
            v-if="i < 5"
            class="mb-1 item_notif"
            @click="toContent(item)"
          >
            <div class="d-flex">
              <div class="mr-2">
                <v-icon :color="!item.is_read ? '#BD9028' : 'transparent'" small
                  >mdi-circle</v-icon
                >
              </div>
              <div>
                <p class="mb-1" v-html="item.text_html"></p>
                <p class="small_txt ma-0">
                  <b style="color: #bd9028">
                    . {{ $date(item.date).fromNow() }}</b
                  >
                </p>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          </div>
        </div>
      <div class="d-flex flex-column align-center" v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="lonceng"
          viewBox="0 0 112 112"
        >
          <rect
            width="18.98"
            height="18.98"
            x="34.96"
            y="82"
            fill="#1876f2"
            rx="9.49"
            transform="rotate(-15 44.445 91.471)"
          />
          <circle cx="43.01" cy="26.27" r="6.85" fill="#BD9028" />
          <path
            fill="#a4a7ab"
            d="M75.28 43.44a26.72 26.72 0 10-51.62 13.83L30 81l51.62-13.87z"
          />
          <path
            fill="#a4a7ab"
            d="M90.78 75.64L26.33 92.9l3.22-13.63 51.62-13.83 9.61 10.2z"
          />
          <rect
            width="66.91"
            height="8.88"
            x="25.35"
            y="80.75"
            fill="#a4a7ab"
            rx="4.44"
            transform="rotate(-15 58.793 85.207)"
          />
        </svg>
        <h3 class="text-center">Tidak ada notifikasi</h3>
      </div>
    </div>
    <div v-else>
      <div v-if="notification.length">
        <div  
            v-for="(item, i) in notification"
            :key="i">
          <div
            v-if="i < 5"
            class="mb-1 item_notif"
            @click="toContent(item)"
          >
            <div class="d-flex">
              <div class="mr-2">
                <v-icon :color="!item.is_read ? '#BD9028' : 'transparent'" small
                  >mdi-circle</v-icon
                >
              </div>
              <div>
                <p class="mb-1" v-html="item.text_html"></p>
                <p class="small_txt  ma-0"
                >
                  <b style="color: #bd9028">
                    . {{ $date(item.date).fromNow() }}</b
                  >
                </p>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          </div>
        </div>
      <div class="d-flex flex-column align-center" v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="lonceng"
          viewBox="0 0 112 112"
        >
          <rect
            width="18.98"
            height="18.98"
            x="34.96"
            y="82"
            fill="#1876f2"
            rx="9.49"
            transform="rotate(-15 44.445 91.471)"
          />
          <circle cx="43.01" cy="26.27" r="6.85" fill="#BD9028" />
          <path
            fill="#a4a7ab"
            d="M75.28 43.44a26.72 26.72 0 10-51.62 13.83L30 81l51.62-13.87z"
          />
          <path
            fill="#a4a7ab"
            d="M90.78 75.64L26.33 92.9l3.22-13.63 51.62-13.83 9.61 10.2z"
          />
          <rect
            width="66.91"
            height="8.88"
            x="25.35"
            y="80.75"
            fill="#a4a7ab"
            rx="4.44"
            transform="rotate(-15 58.793 85.207)"
          />
        </svg>
        <h3 class="text-center">Tidak ada notifikasi</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {getFirestore, doc, updateDoc} from "firebase/firestore";
export default {
  name: "notification",
  props: ["isUnRead"],
  computed: {
    ...mapState({
      notification: (state) => state.notification,
      notificationUnRead: (state) => state.notificationUnRead,
    }),
  },
  data() {
    return {
      read: "",
    };
  },
  mounted() {
  },
  methods: {
    async toContent(item) {
      this.loading = true;
      await updateDoc(doc(getFirestore(), "notification", item.id), {
        is_read:true,
      });
      this.$router.push(item.url_page);
      this.$emit("close");
      // this.$emit("get");
      setTimeout(
        function () { 
          this.loading = false;
        }.bind(this)
      , 300);
    },
  },
};
</script>

<style scoped>
.item_notif {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.item_notif:hover {
  background: #f2f2f2;
}
.lonceng {
  width: 150px;
  height: 150px;
}
</style>
