import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import Home from "../views/Home.vue";
import Registrasi from "../views/HomeRegistrasiClient.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: { requiresVisitor: true },
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("../views/Dashboard/mainDashboard.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/server-error",
        name: "internal500",
        component: () => import("../views/Others/serverError"),
        meta: { requiresAuth: true },
      },
      // DASHBOARD SUPERADMIN
      {
        path: "/admin/:type/rekap-client",
        name: "rekapClient",
        component: () => import("../views/DashboardSuperadmin/rekapClient.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/admin/:type/rekap-all-client",
        name: "rekapAllClient",
        component: () => import("../views/DashboardSuperadmin/rekapAllClient.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/admin/:type/rekap-client/detail/:idKlien",
        name: "detailRekapClient",
        component: () => import("../views/DashboardSuperadmin/detailRekapClient.vue"),
        meta: { requiresAuth: true },
      },
      
      {
        path: "/admin/:type/setup-form-pendaftaran",
        name: "setupDashboardPendaftaran",
        component: () =>
          import("../views/DashboardSuperadmin/setupPendaftaranSuperadmin.vue"),
        meta: { requiresAuth: true },
      },
      
      {
        path: "/admin/:type/setup-event-timeline",
        name: "setupEvent",
        component: () =>
          import("../views/DashboardSuperadmin/setupTimelineEventSuperadmin.vue"),
        meta: { requiresAuth: true },
      },

      {
        path: "/admin/:type/activity",
        name: "logActivity",
        component: () =>
          import("../views/DashboardSuperadmin/logActivity.vue"),
        meta: { requiresAuth: true },
      },

      // COMPLAINT
      {
        path: "/complaint",
        name: "complaintPage",
        component: () => import("../views/Complaint/complaintPage.vue"),
        meta: { requiresAuth: true },
      },

      // KLIEN
      {
        path: "/admin/:type/manage-client",
        name: "listClient",
        component: () => import("../views/Client/listClient.vue"),
        meta: { requiresAuth: true },
      },

      // ACCOUNT OFFICER
      {
        path: "/admin/:type/account-officer",
        name: "listOfficer",
        component: () => import("../views/AccountOfficer/listOfficer"),
        meta: { requiresAuth: true },
      },

      // KEHADIRAN
      {
        path: "/admin/:type/client-attendence",
        name: "listClientAttendence",
        component: () => import("../views/Attendence/listClientAttendence.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/client-attendence/attendence/:idClient",
        name: "listAttendence",
        component: () => import("../views/Attendence/listAttendence.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/client-attendence/attendence/:idClient/history/:idUser",
        name: "historyAttendence",
        component: () => import("../views/Attendence/historyAttendence.vue"),
        meta: { requiresAuth: true },
      },

      // KARYAWAN
      {
        path: "/admin/:type/client-employee",
        name: "listClientEmployee",
        component: () => import("../views/Employee/listClientEmployee.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/client-employee/employee/:idClient",
        name: "listEmployee",
        component: () => import("../views/Employee/listEmployee.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/client-employee/employee/:idClient/create",
        name: "createEmployee",
        component: () => import("../views/Employee/createEmployee.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/client/employee/:idClient/update/:idUser",
        name: "updateEmployee",
        component: () => import("../views/Employee/updateEmployee.vue"),
        meta: { requiresAuth: true },
      },

      // GAJI
      {
        path: "/admin/:type/salary",
        name: "manageSalary",
        component: () => import("../views/Salary/manageSalary.vue"),
        meta: { requiresAuth: true },
      },

      // INVOICES
      {
        path: "/admin/:type/invoice",
        name: "listInvoice",
        component: () => import("../views/Invoice/listInvoice.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/invoice/create",
        name: "createInvoice",
        component: () => import("../views/Invoice/createInvoice.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/invoice/update/:idInvoice",
        name: "updateInvoice",
        component: () => import("../views/Invoice/updateInvoice.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/invoice/detail/:idInvoice",
        name: "detailInvoice",
        component: () => import("../views/Invoice/detailInvoice.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/invoice/detail/:idInvoice/penerimaan",
        name: "createPenerimaan",
        component: () => import("../views/Invoice/createPenerimaan.vue"),
        meta: { requiresAuth: true },
      },

      // INVOICES AUTOMATIC
      {
        path: "/admin/:type/invoice-event/create/:idEvent",
        name: "createInvoiceAutomatic",
        component: () =>
          import("../views/InvoiceAutomatic/createInvoiceAutomatic.vue"),
        meta: { requiresAuth: true },
      },

      // EVENT
      {
        path: "/admin/:type/event",
        name: "listEvent",
        component: () => import("../views/Event/listEvent.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/event/create",
        name: "listLayanan",
        component: () => import("../views/Event/createEvent.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/event/detail/:idEvent",
        name: "detailEvent",
        component: () => import("../views/Event/detailEvent.vue"),
        meta: { requiresAuth: true },
      },

      // CUSTOMER SERVICES
      {
        path: "/admin/:type/chat-room",
        name: "chatRoom",
        component: () => import("../views/CustomerServices/chatRoom.vue"),
        meta: { requiresAuth: true },
      },

      // ASSOCIATE
      {
        path: "/admin/:type/associate",
        name: "listAssociate",
        component: () => import("../views/Associate/listAssociate.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/associate/create",
        name: "createAssociate",
        component: () => import("../views/Associate/createAssociate.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/associate/:idUser",
        name: "updateAssociate",
        component: () => import("../views/Associate/updateAssociate.vue"),
        meta: { requiresAuth: true },
      },

      // SET UP PENDAFTARAN
      {
        path: "/admin/:type/setup-pendaftaran",
        name: "setupPendaftaran",
        component: () =>
          import("../views/SetupRegistration/setupPendaftaran.vue"),
        meta: { requiresAuth: true },
      },

      // PSYCHOLOGY
      {
        path: "/admin/:type/psychology",
        name: "listTest",
        component: () => import("../views/TestPsychology/listTest.vue"),
        meta: { requiresAuth: true },
      },

      // CUSTOMER CLIENT
      {
        path: "/admin/:type/manage-customer",
        name: "listCustomer",
        component: () => import("../views/Customer/listCustomer.vue"),
        meta: { requiresAuth: true },
      },

      // SCHEDULE
      {
        path: "/admin/:type/schedule",
        name: "listSchedule",
        component: () => import("../views/Schedule/listSchedule.vue"),
        meta: { requiresAuth: true },
      },

      // EVENT COMMUNICATION DEVELOPEMENT
      {
        path: "/admin/:type/community-activities",
        name: "communityActivities",
        component: () =>
          import(
            "../views/CommunityDevelopmentActivities/communityActivities.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/community-activities/create",
        name: "createCommunityActivities",
        component: () =>
          import(
            "../views/CommunityDevelopmentActivities/createCommunityActivities.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/community-activities/update/:idActivities",
        name: "updateCommunityActivities",
        component: () =>
          import(
            "../views/CommunityDevelopmentActivities/updateCommunityActivities.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/community-activities/detail/:idActivities",
        name: "detailCommunityActivities",
        component: () =>
          import(
            "../views/CommunityDevelopmentActivities/detailCommunityActivities.vue"
          ),
        meta: { requiresAuth: true },
      },

      // TASK MANAGEMENT
      {
        path: "/admin/:type/task-management",
        name: "listTaskManagement",
        component: () =>
          import("../views/TaskManagement/listTaskManagement.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/task-management/detail-task/:idTask",
        name: "createUpdateDetailTask",
        component: () =>
          import("../views/TaskManagement/createUpdateDetailTask.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/:type/task-management/detail/:idTask",
        name: "detailTask",
        component: () => import("../views/TaskManagement/detailTask.vue"),
        meta: { requiresAuth: true },
      },

      // PROFILE
      {
        path: "/admin/profile",
        name: "pageProfile",
        component: () => import("../views/Profile/pageProfile.vue"),
        meta: { requiresAuth: true },
      },

      // EXPENDITURE
      {
        path: "/admin/:type/expenditure",
        name: "listExpenditure",
        component: () => import("../views/Expenditure/listExpenditure.vue"),
        meta: { requiresAuth: true },
      },

      // NOTIFICATION
      {
        path: "/notifications",
        name: "pageNotif",
        component: () => import("../views/Notifikasi/pageNotif.vue"),
        meta: { requiresAuth: true },
      },

      // ACCOUNTANCY
      {
        path: "/admin/:type/accountancy",
        name: "listAccountancy",
        component: () => import("../views/Accountancy/generateAccountancy.vue"),
        meta: { requiresAuth: true },
      },

      // All Document
      {
        path: "/admin/:type/all-document",
        name: "listAllDocument",
        component: () => import("../views/AllDocument/listAllDocument.vue"),
        meta: { requiresAuth: true },
      },

      // Instansi Partner
      {
        path: "/admin/:type/instansi-partner",
        name: "listPartner",
        component: () => import("../views/InstansiPartner/listPartner.vue"),
        meta: { requiresAuth: true },
      },
      // Psychogram Template
      {
        path: "/admin/:type/report/psychogram-template",
        name: "psychogramTemplate",
        component: () =>
          import("../views/PsychogramTemplate/psychogramTemplate.vue"),
        meta: { requiresAuth: true },
      },
      // Report Manajemen Operasional
      {
        path: "/admin/:type/report/manajemen-operasional",
        name: "reportManajemenOperasional",
        component: () =>
          import(
            "../views/ReportManajemenOperasional/reportManajemenOperasional.vue"
          ),
        meta: { requiresAuth: true },
      },
      // Report Manajemen Karyawan
      {
        path: "/admin/:type/report/manajemen-karyawan",
        name: "reportManajemenKaryawan",
        component: () =>
          import(
            "../views/ReportManajemenKaryawan/reportManajemenKaryawan.vue"
          ),
        meta: { requiresAuth: true },
      },
      // Report Manajemen Community Development
      {
        path: "/admin/:type/report/community-development",
        name: "reportCommunityDevelopment",
        component: () =>
          import(
            "../views/ReportCommunityDevelopment/reportCommunityDevelopment.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/auth/Login",
    name: "Login",
    component: () => import("../views/Auth/Login.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/api/registration/customer",
    component: Registrasi,
    meta: { requiresVisitor: true },
    children: [
      {
        path: "/api/registration/customer/:form/:idRegistration",
        name: "registrasiClient",
        component: () =>
          import("../views/SetupRegistration/registrasiClient.vue"),
        meta: { requiresVisitor: true },
      },
    ],
  },
  {
    path: "/reset-password/:token/:email",
    name: "resetPassword",
    component: () => import("../views/ResetPassword/resetPassword.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/verification/:idverify",
    name: "verification",
    component: () => import("../views/VerificationEmail/verification.vue"),
    meta: { requiresVisitor: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getUser) {
      next({
        path: "/auth/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.getUser) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
