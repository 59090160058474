import { render, staticRenderFns } from "./createTask.vue?vue&type=template&id=5bf8804a&"
import script from "./createTask.vue?vue&type=script&lang=js&"
export * from "./createTask.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCol,VDataTable,VFileInput,VList,VListItem,VMenu,VPagination,VRow,VSelect,VSkeletonLoader})
