<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama</b></p>
          <v-text-field
            placeholder="Silakan isi nama di sini"
            v-model="field.nama"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt">
            <b>No. Telepon</b>
          </p>
          <v-text-field
            v-model="field.phone"
            placeholder="Silakan isi no telepon di sini"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Email</b></p>
          <v-text-field
            v-model="field.email"
            placeholder="Silakan isi email di sini"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="12">
          <p class="small_txt ma-0"><b>Kata Sandi</b></p>
          <v-text-field
            v-model="field.password"
            placeholder="Silakan isi kata sandi di sini"
            color="#0D47A1"
            :error="errors.password"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          ></v-text-field>
          <div v-if="errors.password">
            <p
              class="small_txt ma-0 red--text"
              v-for="(err, idx) in errors.password"
              :key="`pwd-${idx}`"
            >
              {{ idx + 1 }}. {{ err }}
            </p>
          </div>
        </v-col> -->
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Alamat</b></p>
          <v-textarea
            placeholder="Silakan isi alamat di sini"
            v-model="field.alamat"
            rows="3"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg mb-2"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="createData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "createClient",

  data() {
    return {
      role: "",
      loading: false,
      field: {
        nama: "",
        email: "",
        phone: "",
        alamat: "",
        // password: "",
      },
      errors: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    createData() {
      let isEmpty = false;
      this.errors = {
        email: null,
        password: null,
      };
      for (const item in this.field) {
        if (!this.field[item]) {
          // if (item == "nama" || item == "email" || item == "password") {
          if (item == "nama" || item == "email") {
            this.$toast.error(`${item} tidak boleh kosong!`);
            isEmpty = true;
          }
        }
      }
      if (!isEmpty) {
        this.loading = true;
        let data = {
          body: {
            nama: this.field.nama,
            email: this.field.email,
            // password: this.field.password,
            telepon: this.field.phone,
            alamat: this.field.alamat,
          },
          path: `sysadmin/klien/create`,
        };
        this.$store
          .dispatch("client/postApi", data)
          .then((data) => {
            this.loading = false;
            if (data.code == 200) {
              for (let i in this.field) {
                this.field[i] = "";
              }
              this.$emit("success");
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "success",
              });
            } else if (data.code == 201) {
              this.errors.email = data.data.errors.email;
              this.errors.password = data.data.errors.password;
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              if (key.length == 0) {
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            }
          })
          .catch((e) => {
            this.loading = false;
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          });
      }
    },
  },
};
</script>
