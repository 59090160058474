import axios from "axios";
import Cookies from "js-cookie";

const Event = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
    env: `${process.env.VUE_APP_ENV}/api`,
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {
    getData(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = context.state.env;
        axios
          .get(`${env}/${data.path}`, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    postApi(context, data) {
      return new Promise((resolve, reject) => {
        let config = context.state.config;
        let env = context.state.env;
        let body = data.body;
        if (data.body.length > 0) {
          let formData = new FormData();
          for (let index = 0; index < data.body.length; index++) {
            if (
              Object.keys(data.body[index]) != "step" &&
              Object.keys(data.body[index]) != "klien_id" &&
              Object.keys(data.body[index]) != "tujuan_event" &&
              Object.keys(data.body[index]) != "tipe" &&
              Object.keys(data.body[index]) != "layanan_id" &&
              Object.keys(data.body[index]) != "timelines"
            ) {
              formData.append(
                [Object.keys(data.body[index])],
                Object.values(data.body[index])[0]
              );
            } else {
              formData.append(
                [Object.keys(data.body[index])],
                Object.values(data.body[index])
              );
            }
          }
          body = formData;
        }
        axios
          .post(`${env}/${data.path}`, body, config)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            let err = error.response ? error.response.data : error;
            reject(err);
          });
      });
    },
  },
};

export default Event;
