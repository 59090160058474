import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Cookies from "js-cookie";
import auth from "./module/auth";
import client from "./module/client";
import officer from "./module/officer";
import userClient from "./module/userClient.js";
import fileData from "./module/fileData.js";
import event from "./module/event";
import Swal from "sweetalert2";
import router from "../router/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: Cookies.get("token") || null, //token
    role: Cookies.get("role"),
    id: Cookies.get("id"),
    env: `${process.env.VUE_APP_ENV}/api`,
    drawer: true,
    code_client: Cookies.get("code_client") || null,
    isMobile: false,
    ava: require("../assets/img/IconProfile.png"),
    permissions: Cookies.get("permissions")
      ? JSON.parse(Cookies.get("permissions"))
      : "",
    profile: null,
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    },
    notification: [],
    notificationUnRead: [],
    arrIsReadNotif: {'read':0,'unread':0},
  },
  getters: {
    getUser(state) {
      return state.token !== null && state.token !== undefined;
    },
  },
  mutations: {
    MUTATE_DRAWER(state, data) {
      state.drawer = data;
    },
    MUTATE_MOBILE(state, data) {
      state.isMobile = data;
    },
    GET_PROFILE(state, data) {
      state.profile = data;
    },
    GET_NOTIF(state, data) {
      state.notification = data;
    },
    GET_NOTIF_UNREAD(state, data) {
      state.notificationUnRead = data;
    },
    SET_COUNT_NOTIF(state, data) {
      state.arrIsReadNotif = data;
    },
  },
  actions: {
    // fungsi tampilkan pop up berhasil get/nambah/edit data atau error
    notify({}, data) {
      Swal.fire({
        icon: data.type,
        title: data.msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
      });
    },
    // fungsi log out di semua halaman
    logout({}, data) {
      Cookies.remove("token");
      Cookies.remove("role");
      router.go("/auth/Login");
    },

    // get notifikasi chat
    getNotify(context, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        };
        let env = context.state.env;

        axios
          .get(
            `${env}/sysadmin/notif?read=${data.read}&page[size]=${data.limit}`,
            config
          )
          .then((res) => {
            resolve(res.data.data);
            context.commit("GET_NOTIF", res.data.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
  modules: {
    auth,
    client,
    officer,
    userClient,
    fileData,
    event,
  },
});
