<template>
  <div v-if="selected">
    <p class="default_txt text-center">Anda yakin ingin menambahkan data?</p>
    <div class="d-flex justify-center mt-2 py-3">
      <v-btn
        outlined
        class="text-capitalize mr-2"
        color="#BD9028"
        @click="$emit('close')"
        dark
      >
        <div style="color: black">Kembali</div>
      </v-btn>
      <v-btn
        depressed
        class="text-capitalize mr-1 bg_color1"
        @click="addItem"
        dark
        >Tambah</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
    <v-overlay :absolute="true" opacity="1" color="white" :value="success">
      <div class="d-flex justify-center">
        <v-icon size="50" color="blue">mdi-delete-empty</v-icon>
      </div>
      <p class="ma-0 color_txt small_txt">
        {{ selected.addType }} berhasil ditambah
      </p>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "addFunction",
  props: ["selected"],

  data() {
    return {
      loading: false,
      success: false,
    };
  },
  methods: {
    addItem() {
      this.loading = true;
      let data = {
        body: this.selected.body,
        path: this.selected.path,
      };
        this.$store
        .dispatch("officer/postApi", data)
        .then((data) => {
          this.loading = false;
          this.success = true;
          setTimeout(() => {
            this.$emit("success");
            this.success = false;
          }, 500);
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: err.message,
            type: "error",
          });
        });
      }
    },
};
</script>
