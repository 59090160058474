var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-1",staticStyle:{"height":"100vh"}},[_c('v-row',{staticStyle:{"height":"8%"},attrs:{"no-gutters":"","align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"pa-3 d-flex align-start justify-center",staticStyle:{"height":"auto"}},[(_vm.drawer && !_vm.isMobile)?_c('v-img',{attrs:{"src":require("../../assets/icon/Logo.png"),"width":"45px","height":"30px","contain":""}}):_vm._e(),(!_vm.drawer || _vm.isMobile)?_c('v-img',{attrs:{"src":require("../../assets/icon/logo_small.png"),"width":"50px","height":"30px","contain":""}}):_vm._e()],1)]),(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"pa-3 d-flex align-start justify-center",staticStyle:{"height":"8vh"}},[_c('v-app-bar-nav-icon',{staticStyle:{"color":"#d0d1d2"},on:{"click":_vm.mutateSide}})],1)]):_vm._e()],1),_c('v-row',{staticStyle:{"height":"79%"},attrs:{"no-gutters":"","align":"start","justify":_vm.isMobile ? 'center' : 'start'}},[_c('v-col',{attrs:{"cols":!_vm.drawer || _vm.isMobile ? 8 : 12}},[_c('div',{class:_vm.drawer && !_vm.isMobile ? 'px-1' : ''},[_c('v-list',{staticClass:"mb-10 px-2 rounded-lg",staticStyle:{"background-color":"#cfe1e1"},attrs:{"dense":""}},[_c('div',{staticClass:"mb-2 active_item_sidebar_expanded flex-column"},[_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"link":"","active-class":"active_item_sidebar"}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-cog-outline")])],1)]}}])},[_c('span',[_vm._v("Operasional")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{staticClass:"txt_Sidebar_Active_Sub"},[_vm._v("Operasional")])],1):_vm._e()],1),_c('v-expand-transition',[_c('div',{staticClass:"sub_dir"},[_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path ==
                        ("/api/registration/customer/" + (this.$route.params.idRegistration))
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile'},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-clipboard-text-outline")])],1)]}}])},[_c('span',[_vm._v("Pendaftaran")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{staticClass:"txt_Sidebar_m_Active ml-2"},[_vm._v(" Pendaftaran ")]):_vm._e()])])])])],1)])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }