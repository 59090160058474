<template>
  <div>
    <div class="pa-5">
      <v-row>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama</b></p>
          <v-text-field
            placeholder="Silakan isi alamat di sini"
            v-model="selected.nama"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt">
            <b>No. Telepon</b>
          </p>
          <v-text-field
            v-model="selected.telepon"
            placeholder="Silakan isi no. telepon di sini"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Email</b></p>
          <v-text-field
            v-model="selected.email"
            placeholder="Silakan isi email di sini"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Alamat</b></p>
          <v-textarea
            placeholder="Silakan isi alamat di sini"
            v-model="selected.alamat"
            rows="3"
            color="#0D47A1"
            dense
            hide-details
            outlined
            class="rounded-lg"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="updateData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "updateClient",
  props: ["selected"],

  data() {
    return {
      role: "",
      loading: false,
    };
  },
  methods: {
    updateData() {
      let isEmpty = false;
      for (const item in this.selected) {
        if (!this.selected[item]) {
          if (item == "nama" || item == "email" || item == "password") {
            this.$toast.error(`${item} tidak boleh kosong!`);
            isEmpty = true;
          }
        }
      }
      if (!isEmpty) {
        this.loading = true;
        let data = {
          body: {
            id: this.selected.id,
            nama: this.selected.nama,
            email: this.selected.email,
            password: this.selected.password,
            telepon: this.selected.telepon,
            alamat: this.selected.alamat,
          },
          path: `sysadmin/klien/update`,
        };
        this.$store
          .dispatch("client/postApi", data)
          .then((data) => {
            this.loading = false;
            if (data.code == 200) {
              this.$emit("success");
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "success",
              });
            } else if (data.code == 201) {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              if (key.length == 0) {
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            }
          })
          .catch((e) => {
            this.loading = false;
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          });
      }
    },
  },
};
</script>
