<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import {getFirestore, onSnapshot, collection, doc, orderBy, query, where, updateDoc} from "firebase/firestore";
export default {
  name: "App",
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      id: (state) => state.id,
      ava: (state) => state.ava,
    }),
  },
  data() {
    return {
      notification: [],
      notificationUnRead: [],
      arrIsRead: {
        'read':0,'unread':0,
      }
    };
  },
  mounted(){
    let profile = setInterval(() => {
      if (this.profile) {
          this.getNotif();
          clearInterval(profile);
      }
    }, 300);
  },
  methods:{
    async getNotif(){
      const q = query(collection(getFirestore(), "notification"), where("recipient", "==", parseInt(this.profile.data.profile.user_id)), orderBy('date','desc'));
      onSnapshot(q, async(querySnapshot) => {
        this.notification = [];
        this.notificationUnRead = [];
        this.arrIsRead.read = 0;
        this.arrIsRead.unread = 0;
        querySnapshot.docs.map(async(item) => {
          this.notification.push(item.data());
          if(item.data().is_read){
            this.arrIsRead.read++;
          }else{
            this.arrIsRead.unread++;
            this.notificationUnRead.push(item.data());
          }
          if(!item.data().is_show_popup){
            this.$toast.info(item.data().text);
            await updateDoc(doc(getFirestore(), "notification", item.data().id), {
              is_show_popup:true,
            });
          }
        })
        
        this.$store.commit("GET_NOTIF", this.notification);
        this.$store.commit("GET_NOTIF_UNREAD", this.notificationUnRead);
        this.$store.commit("SET_COUNT_NOTIF", this.arrIsRead);
      });
    }
  }
};
</script>
