<template>
  <div>
    <v-breadcrumbs class="ma-0 pa-0" :items="items">
      <template v-slot:divider>
        <div class="d-flex align-center">
          <v-icon small class="ma-0 pa-0">mdi-chevron-right</v-icon>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div class="d-flex align-center">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            <h4
              class="not_bold"
              :style="`color: ${
                !item.disabled ? 'rgba(0, 0, 0, 0.87)' : '#106967'
              }`"
            >
              {{ item.text }}
            </h4>
          </v-breadcrumbs-item>
        </div>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  props: ["items"],
  name: "breadcrumbs",
};
</script>

<style>
.v-breadcrumbs__divider {
  padding: 0 !important;
}
</style>
