<template>
  <div>
    <div class="px-5 py-2">
      <v-row no-gutters>
        <v-col cols="12" v-if="selected && dataSet">
          <h4 class="txt_color_default">Pilih Customer Services</h4>
          <v-text-field
            v-model="find"
            hide-details
            solo
            dense
            flat
            background-color="#CFE1E1"
            class="rounded-lg mt-1"
            placeholder="Search for people"
            prepend-inner-icon="mdi-magnify"
            @keyup="fetchData"
          ></v-text-field>
          <v-data-table
            v-if="selected && dataSet"
            v-model="field.to_user_id"
            :headers="headers"
            :items="dataSet"
            item-key="id"
            show-select
            class="elevation-0"
            hide-default-footer
            :single-select="false"
          >
            <template v-slot:[`item.avatar`]="{ item }">
              <div class="d-flex text-capitalize">
                <v-avatar dark size="35">
                  <img
                    :src="item.avatar ? item.avatar : ava"
                    alt="avatar"
                    class="obj_fit"
                  />
                </v-avatar>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pa-3" v-if="selected && dataSet">
            <v-pagination
              color="#052633"
              v-model="page"
              @input="fetchData"
              :length="last_page"
            ></v-pagination>
          </div>
        </v-col>
        <v-col cols="12">
          <h4 class="mt-3 txt_color_default">Pertanyaan</h4>
          <v-text-field
            class="my-2 rounded-lg"
            v-model="field.message"
            placeholder="Tulis pertanyaan disini"
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex align-center justify-end mt-2">
      <v-btn
        outlined
        depressed
        color="#BD9028"
        class="text-capitalize mr-2"
        @click="$emit('close')"
        dark
        >Batal</v-btn
      >
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="setDocumentFirebase"
        dark
        >Kirim</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {getFirestore, collection, doc, setDoc, addDoc, query, where, getDoc, getDocs} from "firebase/firestore";

export default {
  name: "createClient",
  props: ["selected"],
  computed: {
    ...mapState({
      ava: (state) => state.ava,
    }),
  },
  data() {
    return {
      menuDate: false,
      role: "",
      loading: false,
      dataSet: null,
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
      find: "",
      field: {
        to_user_id: [],
        message: "",
      },
      errors: {
        email: null,
        password: null,
      },
      headers: [
        { text: "Avatar", value: "avatar" },
        { text: "Nama", value: "nama" },
        { text: "Posisi", value: "jabatan" },
      ],
      idThreadPerChat: '',
      idSubThreadPerChat: '',
    };
  },
  mounted() {
    this.getTimeState();
    this.fetchData();
  },
  methods: {
    getTimeState() {
      //  Get the current time
      let timeNow = new Date();
      //  Get the current hour
      let hours = timeNow.getHours();
      //  Set default text
      let state = ``;
      //  Judge the current time period
      if (hours >= 0 && hours <= 10) {
        state = `Selamat pagi`;
      } else if (hours > 10 && hours <= 14) {
        state = `Good siang`;
      } else if (hours > 14 && hours <= 18) {
        state = `Good sore`;
      } else if (hours > 18 && hours <= 24) {
        state = `Selamat malam`;
      }
      this.field.message = `${state}, saya ingin bertanya sesuatu.`;
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/karyawan/akun/list?page[size]=${this.limit}&page[number]=${this.page}&filter[klien_id]=${this.selected.data.profile.klien_id}&filter[nama]=${this.find}`,
      };
      this.$store
        .dispatch("userClient/getData", data)
        .then((data) => {
          for (let i = 0; i < data.data.length; i++) {
            if (data.data[i].user_id == this.selected.data.profile.user_id) {
              data.data.splice(i, 1);
            }
          }
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
   async setDocumentFirebase(){
      this.loading = true;
      for (const item of this.field.to_user_id) {
        const docRefSendMessage = query(collection(getFirestore(), "chats"), where('user_id', '==', item.user_id),  where('to_user_id', '==', this.selected.data.profile.user_id));
        const docSnapSendMessage = await getDocs(docRefSendMessage);
        const docRefRecivedMessage = query(collection(getFirestore(), "chats"), where('user_id', '==', this.selected.data.profile.user_id), where('to_user_id', '==', item.user_id));
        const docSnapRecivedMessage = await getDocs(docRefRecivedMessage);
        await this.randomIdChat();
        if(docSnapSendMessage.docs.length == 0 && docSnapRecivedMessage.docs.length == 0){
          setDoc(doc(getFirestore(), "chats",this.idThreadPerChat.toString()), {
            id:this.idThreadPerChat,
            user_id: this.selected.data.profile.user_id,
            to_user_id: item.user_id,
            name: this.selected.data.profile.nama,
            avatar: this.selected.data.profile.avatar,
            to_profile: item,
            profile: this.selected.data.profile,
            date:Date.now(),
            update_date:Date.now(),
          });
          await this.sendMessageDefault(item);
          this.$toast.success('Berhasil menambah chat dengan '+item.nama);
        }else{
          this.$toast.error('Anda sudah pernah chat dengan '+item.nama);
        }
      }
      this.field.to_user_id = [];
      this.loading = false;
      this.$emit("success");
    },
   async randomIdChat(){
      let id = Math.random().toString(16).slice(4);
      
      let docRef = doc(getFirestore(), "chats", id.toString());
      let docSnap = await getDoc(docRef);
      
      do {
          id = Math.random().toString(16).slice(4);
          docRef = doc(getFirestore(), "chats", id.toString());
          docSnap = await getDoc(docRef);
      } while (docSnap.exists()){
        this.idThreadPerChat = id;
      }
    },
    async sendMessageDefault(data){
      addDoc(collection(getFirestore(), "chats/"+this.idThreadPerChat+"/messages"),{
        user_id:this.selected.data.profile.user_id,
        to_user_id:data.user_id,
        text:this.field.message,
        replay:'',
        is_file:false,
        file:'',
        to_profile: data,
        profile: this.selected.data.profile,
        date:Date.now(),
      });
      
      addDoc(collection(getFirestore(), "chats/"+this.idThreadPerChat+"/messages"),{
        user_id:data.user_id,
        to_user_id:this.selected.data.profile.user_id,
        text:'Terima kasih telah menghubungi, beberapa saat lagi saya akan menjawabnya',
        replay:'',
        is_file:false,
        file:'',
        to_profile: data,
        profile: this.selected.data.profile,
        date: new Date(Date.now() + (1 * 60 * 1000)).getTime(),
      });

      await this.randomIdSubChat();
      setDoc(doc(getFirestore(), "notification/"+this.idSubThreadPerChat.toString()),{
        id: this.idSubThreadPerChat,
        sender: this.selected.data.profile.user_id,
        recipient: data.user_id,
        text: this.selected.data.profile.nama + ' mengirimi Anda sebuah pesan',
        text_html:'<b>' + this.selected.data.profile.nama + '</b> mengirimi Anda sebuah pesan',
        url_page: '/admin/mk/chat-room',
        is_read: false,
        klien_id: data.klien_id,
        date: Date.now(),
      });
    },
    async randomIdSubChat(){
      let id = Math.random().toString(13).slice(2);
      
      let docRef = doc(getFirestore(), "notification/"+id.toString());
      let docSnap = await getDoc(docRef);
      
      do {
          id = Math.random().toString(13).slice(2);
          docRef = doc(getFirestore(), "notification/"+id.toString())
          docSnap = await getDoc(docRef);
      } while (docSnap.exists()){
        this.idSubThreadPerChat = id;
      }
    },
    createData() {
      this.loading = true;
      let data = {
        body: [
          {
            message: this.field.message,
          },
          {
            to_user_id: JSON.stringify(this.field.to_user_id),
          },
          {
            is_file: 0,
          },
        ],
        path: `sysadmin/customer-services/add-chat`,
      };
      // this.$store
      //   .dispatch("fileData/postApi", data)
      //   .then((data) => {
      //     this.loading = false;
      //     if (data.status == "success") {
      //       this.field.to_user_id = [];
      //       this.field.message = [];
      //       this.$emit("success");
      //       this.$store.dispatch("notify", {
      //         msg: data.message,
      //         type: "success",
      //       });
      //     } else {
      //       var key = Object.keys(data.data.errors);
      //       var values = Object.values(data.data.errors);
      //       if (key.length == 0) {
      //         this.$store.dispatch("notify", {
      //           msg: data.message,
      //           type: "error",
      //         });
      //       }
      //       for (let i = 0; i < key.length; i++) {
      //         for (let j = 0; j < values[i].length; j++) {
      //           this.$toast.error(values[i][j]);
      //         }
      //       }
      //     }
      //   })
      //   .catch((e) => {
        //   this.loading = false;
        //   var key = Object.keys(e.data.errors);
        //   var values = Object.values(e.data.errors);
        //   if (key.length == 0) {
        //     this.$store.dispatch("notify", {
        //       msg: e.message,
        //       type: "error",
        //     });
        //   }
        //   for (let i = 0; i < key.length; i++) {
        //     for (let j = 0; j < values[i].length; j++) {
        //       this.$toast.error(values[i][j]);
        //     }
        //   }
        // });
    
      
    },
  },
};
</script>
