<template>
  <input
    ref="inputRef"
    :disabled="disabled"
    :placeholder="placeholder"
    :style="`background:${disabled ? '#F0F0F0' : 'transparent'}`"
  />
</template>

<script>
import { watch } from "@vue/composition-api";
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    value: Number, // Vue 2: value
    options: Object,
    class_temp: "",
    disabled: false,
    placeholder: "",
  },
  setup(props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options);

    watch(
      () => props.value, // Vue 2: props.value
      (value) => {
        setValue(value);
      }
    );

    watch(
      () => props.options,
      (options) => {
        setOptions(options);
      }
    );

    return { inputRef };
  },
};
</script>
<style>
input[type="text"]:focus {
  border: 0px;
  outline: none;
}
</style>
