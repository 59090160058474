<template>
  <div>
    <v-app-bar v-if="this.$route.path == '/'" color="white" flat height="auto">
      <v-row no-gutters style="width: 100%" align="center">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown && isMobile"
            class="mr-2"
            @click="mutateSide"
          >
          </v-app-bar-nav-icon>
          <h4>Dashboard</h4>
          <v-btn icon class="ml-2" color="black">
            <v-icon color="black">mdi-bell</v-icon>
          </v-btn>
        </div>
        <v-col
          cols="12"
          :md="$vuetify.breakpoint.mdAndDown && isMobile ? '9' : '10'"
        >
          <v-text-field
            hide-details
            solo
            dense
            flat
            outlined
            class="txt_input rounded-lg txt_search"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-app-bar>
    <desktop-sidebar v-if="!$vuetify.breakpoint.mdAndDown" />
    <mobile-sidebar v-if="$vuetify.breakpoint.mdAndDown" />
    <div class="side_active">
      <router-view
        :class="
          $vuetify.breakpoint.mdAndDown && isMobile
            ? 'bg_main full_mainMobile'
            : 'bg_main full_main'
        "
      ></router-view>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import DesktopSidebar from "./Sidebar/desktopSide.vue";
import MobileSidebar from "./Sidebar/mobileSide.vue";
export default {
  name: "Home",
  components: { DesktopSidebar, MobileSidebar },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      role: (state) => state.role,
      profile: (state) => state.profile,
    }),
  },
  created() {},
  data() {
    return {
      toggler: true,
    };
  },
  mounted() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.$store.commit("MUTATE_DRAWER", false);
      this.$store.commit("MUTATE_MOBILE", true);
      this.toggler = false;
    }
  },
  methods: {
    mutateSide() {
      if (this.toggler == this.drawer) {
        this.toggler = !this.toggler;
      }
      this.$store.commit("MUTATE_DRAWER", this.toggler);
    },
  },
};
</script>
<style scoped>
.nav_round {
  border-radius: 10px 0 0 0 !important;
}
.min_top {
  margin-top: -5px;
}

@media (min-width: 396px) {
}

@media (max-width: 575px) {
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

@media (min-width: 960px) {
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media (min-width: 1440px) {
}
</style>
