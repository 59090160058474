<template>
  <div style="width: 420px;">
    <div class="pa-5">
      <v-data-table
          :headers="headers"
          :items="selected"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          :single-select="false"
          class="elevation-0 full_w"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    @click="deleteData(item.id)"
                    :disabled="item.created_by!=null?false:true"
                  >
                    <v-icon> mdi-delete-circle-outline </v-icon>
                  </v-btn>
                </template>
                <span>Delete Layanan ini</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
    </div>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        dark
        >Tambah</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "createEventLayanan",
  props: ["selected"],
  data() {
    return {
      loading: false,
      headers: [
        { text: "Nama Layanan", value: "layanan" },
        { text: "Action", value: "actions", sortable: false },
      ],
      page: 1,
      limit: 10,
    };
  },
  methods: {
    deleteData(index) {
      this.loading = true;
      let data = {
        body: {
          id: index,
        },
        path: `sysadmin/event/daftar-layanan/custom-admin/delete`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.$emit("success");
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
  },
};
</script>
