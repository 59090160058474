<template>
  <div class="px-3" style="width: 550px">
    <div>
      <h3 class="text-center black_txt">
        {{ selected.layanan }} - {{ selected.customer }}
      </h3>
      <v-stepper elevation="0" alt-labels v-if="selected">
        <div id="scroll-target" class="overflow-y-auto" style="max-width: 100%">
          <v-stepper-header class="pa-3" v-scroll:#scroll-target="onScroll">
            <div class="d-flex align-center justify-center full_w">
              <template v-for="(item, n) in selected.timeline">
                <v-stepper-step
                  color="#052633"
                  :key="n"
                  :step="n + 1"
                  :complete="item.status == 1 ? true : false"
                  :complete-icon="`mdi-numeric-${n + 1}`"
                >
                  {{ item.step }}
                </v-stepper-step>
                <v-divider
                  class="bg_color1"
                  :key="`divider${n}`"
                  v-if="n < selected.timeline.length - 1"
                />
              </template>
            </div>
          </v-stepper-header>
        </div>
      </v-stepper>
      <h4 class="not_bold text-center black_txt">
        Lihat selengkapnya dengan klik di bawah ini
      </h4>
      <div class="mt-2 d-flex align-center justify-center">
        <v-btn
          depressed
          class="bg_color1 text-capitalize"
          dark
          :to="`/admin/mo/event/detail/${selected.id}`"
        >
          See Detail
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "timeline",
  props: ["selected"],
  data() {
    return {
      offsetTop: 0,
    };
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
  },
};
</script>

<style></style>
