<template>
  <v-row justify="start">
    <v-dialog
      v-model="d_large"
      persistent
      scrollable
      max-width="600px"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
    >
      <v-card class="rounded_max hide_overflow" flat>
        <v-toolbar dense flat>
          <h3>{{ title }}</h3>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider v-if="line"></v-divider>
        <v-card-text class="px-2 pb-0">
          <v-container style="height: max-content" class="pt-1">
            <!-- ATTENDENCE -->
            <import-attendence
              :selected="inventory"
              v-if="type == 'i_attendence'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />

            <!-- SALARY -->
            <import-salary
              :selected="inventory"
              v-if="type == 'i_salary'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />
            <import-salary-bayar
              :selected="inventory"
              v-if="type == 'i_salary_bayar'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />

            <!-- TIMELINE EVENT -->
            <timeline
              :selected="inventory"
              v-if="type == 'timeline_dashboard'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />

            <!-- EXPENDITURE -->
            <import-expenditure
              :selected="inventory"
              v-if="type == 'i_expenditure'"
              @success="closeAndFetch"
              @close="$emit('close')"
            />

            <!-- SCHEDULE -->
            <create-schedule
              v-if="type == 'c_schedule'"
              @success="closeAndFetch"
              :selected="inventory"
              @close="$emit('close')"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import importAttendence from "../Attendence/importAttendence.vue";
import Timeline from "../Dashboard/timeline.vue";
import ImportExpenditure from "../Expenditure/importExpenditure.vue";
import ImportSalary from "../Salary/importSalary.vue";
import ImportSalaryBayar from "../Salary/importSalaryBayar.vue";
import CreateSchedule from "../Schedule/createSchedule.vue";
export default {
  components: {
    importAttendence,
    ImportSalary,
    Timeline,
    ImportExpenditure,
    ImportSalaryBayar,
    CreateSchedule,
  },
  props: ["d_large", "type", "title", "inventory", "line"],
  methods: {
    closeAndFetch() {
      this.$emit("refetch");
      this.$emit("close");
    },
  },
};
</script>

<style></style>
