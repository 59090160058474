<template>
  <div>
    <div class="px-1">
      <v-row no-gutters align="start" justify="center">
        <v-col cols="8">
          <div
            class="pa-3 d-flex align-start justify-center"
            style="height: auto"
          >
            <v-img
              v-if="drawer && !isMobile"
              src="../../assets/icon/Logo.png"
              width="45px"
              height="30px"
              contain
            >
            </v-img>
            <v-img
              v-if="!drawer || isMobile"
              src="../../assets/icon/logo_small.png"
              width="50px"
              height="30px"
              contain
            >
            </v-img>
          </div>
        </v-col>
        <v-col cols="4" v-if="!isMobile">
          <div
            class="pa-3 d-flex align-start justify-center"
            style="height: 8vh"
          >
            <v-app-bar-nav-icon
              @click="mutateSide"
              style="color: #568280"
            ></v-app-bar-nav-icon>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters align="start" :justify="isMobile ? 'center' : 'start'">
        <v-col :cols="!drawer || isMobile ? 8 : 12">
          <div :class="drawer && !isMobile ? 'px-1' : 'px-1'">
            <v-list
              class="px-2 rounded-lg"
              dense
              style="background-color: #cfe1e1"
            >
              <v-list-item
                active-class="active_item_sidebar"
                class="unactive_item_sidebar"
                link
                to="/"
                @click="changeState('db', 'single')"
              >
                <v-list-item-icon class="mr-0 pa-0">
                  <v-tooltip
                    nudge-right="15"
                    right
                    :disabled="drawer && !isMobile ? true : false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon
                          :class="path == '/' ? 'icon_active' : 'icon_unactive'"
                        >
                          mdi-home-outline
                        </v-icon>
                      </div>
                    </template>
                    <span>Dashboard</span>
                  </v-tooltip>
                </v-list-item-icon>

                <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                  <v-list-item-title
                    :class="
                      path == '/'
                        ? 'txt_Sidebar_Active'
                        : 'txt_Sidebar_nonActive'
                    "
                    >Dashboard</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="role == 'SUPERADMIN'"
                active-class="active_item_sidebar"
                class="unactive_item_sidebar"
                link
                to="/admin/ao/account-officer"
                @click="changeState('ao')"
              >
                <v-list-item-icon class="mr-0 pa-0">
                  <v-tooltip
                    nudge-right="15"
                    right
                    :disabled="drawer && !isMobile ? true : false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon
                          :class="sideItem.ao ? 'icon_active' : 'icon_unactive'"
                          >mdi-book-account-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Account Officer</span>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                  <v-list-item-title
                    :class="
                      sideItem.ao
                        ? 'txt_Sidebar_Active'
                        : 'txt_Sidebar_nonActive'
                    "
                    >Account Officer</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              
              <div
                v-if="role == 'SUPERADMIN'"
                :class="
                  sideItem.mo
                    ? 'active_item_sidebar_expanded flex-column'
                    : 'unactive_item_sidebar_expanded'
                "
              >
                <v-list-item
                  link
                  @click="changeState('mo')"
                  active-class="active_item_sidebar"
                  class="unactive_item_sidebar"
                >
                  <v-list-item-icon class="mr-0 pa-0">
                    <v-tooltip
                      nudge-right="15"
                      right
                      :disabled="drawer && !isMobile ? true : false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon
                            :class="
                              sideItem.mo
                                ? 'icon_unactive_sub'
                                : 'icon_unactive'
                            "
                            >mdi-chart-bar</v-icon
                          >
                        </div>
                      </template>
                      <span>Biro Analytics</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                    <v-list-item-title
                      :class="
                        sideItem.mo
                          ? 'txt_Sidebar_Active_Sub'
                          : 'txt_Sidebar_nonActive_Sub'
                      "
                      >Biro Analytics</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-expand-transition>
                  <div class="sub_dir" v-if="sideItem.mo">
                    <div
                      class="px-2"
                    >
                      <div
                        :class="
                          path == '/admin/mo/rekap-client'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/rekap-client')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/rekap-client'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-chart-line</v-icon
                                >
                              </div>
                            </template>
                            <span>Per Klien</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/rekap-client'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Per Klien
                        </div>
                      </div>
                    </div>
                    <div class="pa-2">
                      <div
                        :class="
                          path == '/admin/mo/rekap-all-client'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/rekap-all-client')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/rekap-all-client'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-chart-multiple</v-icon
                                >
                              </div>
                            </template>
                            <span>Semua Klien</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/rekap-all-client'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                        Semua Klien
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
              <div
                v-if="role == 'SUPERADMIN'"
                :class="
                  sideItem.setup
                    ? 'active_item_sidebar_expanded flex-column'
                    : 'unactive_item_sidebar_expanded'
                "
              >
                <v-list-item
                  link
                  @click="changeState('setup')"
                  active-class="active_item_sidebar"
                  class="unactive_item_sidebar"
                >
                  <v-list-item-icon class="mr-0 pa-0">
                    <v-tooltip
                      nudge-right="15"
                      right
                      :disabled="drawer && !isMobile ? true : false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon
                            :class="
                              sideItem.setup
                                ? 'icon_unactive_sub'
                                : 'icon_unactive'
                            "
                            >mdi-file-cog</v-icon
                          >
                        </div>
                      </template>
                      <span>Setup</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                    <v-list-item-title
                      :class="
                        sideItem.setup
                          ? 'txt_Sidebar_Active_Sub'
                          : 'txt_Sidebar_nonActive_Sub'
                      "
                      >Setup</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-expand-transition>
                  <div class="sub_dir" v-if="sideItem.setup">
                    <div
                      class="px-2"
                    >
                      <div
                        :class="
                          path == '/admin/setup/setup-form-pendaftaran'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/setup/setup-form-pendaftaran')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/setup/setup-form-pendaftaran'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-file-arrow-left-right-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Setup Form Pendaftaran</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/setup/setup-form-pendaftaran'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Setup Form Pendaftaran
                        </div>
                      </div>
                    </div>
                    <div class="pa-2">
                      <div
                        :class="
                          path == '/admin/setup/setup-event-timeline'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/setup/setup-event-timeline')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/setup/setup-event-timeline'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-calendar-edit</v-icon
                                >
                              </div>
                            </template>
                            <span>Setup Timeline Event</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/setup/setup-event-timeline'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                        Setup Timeline Event
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
              <div
                v-if="role != 'SUPERADMIN'"
                :class="
                  sideItem.mo
                    ? 'active_item_sidebar_expanded flex-column'
                    : 'unactive_item_sidebar_expanded'
                "
              >
                <v-list-item
                  link
                  @click="changeState('mo')"
                  active-class="active_item_sidebar"
                  class="unactive_item_sidebar"
                >
                  <v-list-item-icon class="mr-0 pa-0">
                    <v-tooltip
                      nudge-right="15"
                      right
                      :disabled="drawer && !isMobile ? true : false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon
                            :class="
                              sideItem.mo
                                ? 'icon_unactive_sub'
                                : 'icon_unactive'
                            "
                            >mdi-cog-outline</v-icon
                          >
                        </div>
                      </template>
                      <span>Manajemen Operasional</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                    <v-list-item-title
                      :class="
                        sideItem.mo
                          ? 'txt_Sidebar_Active_Sub'
                          : 'txt_Sidebar_nonActive_Sub'
                      "
                      >Manajemen Operasional</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-expand-transition>
                  <div class="sub_dir" v-if="sideItem.mo">
                    <div
                      class="px-2"
                      v-if="role != 'ACCOUNTOFFICER' && role != 'SUPERADMIN'"
                    >
                      <div
                        :class="
                          path == '/admin/mo/event'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/event')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/event'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-text-box</v-icon
                                >
                              </div>
                            </template>
                            <span>Manajemen Event</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/event'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Manajemen Event
                        </div>
                      </div>
                    </div>
                    <div class="pa-2" v-if="role != 'SUPERADMIN'">
                      <div
                        :class="
                          path == '/admin/mo/schedule'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/schedule')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/schedule'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-calendar-account</v-icon
                                >
                              </div>
                            </template>
                            <span>Schedule</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/schedule'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Schedule
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="role != 'SUPERADMIN' && role != 'ACCOUNTOFFICER'"
                    >
                      <div
                        :class="
                          path == '/admin/mo/all-document'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/all-document')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/all-document'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-file-document-multiple-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>All Document</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/all-document'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          All Document
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="role != 'SUPERADMIN' && role != 'ACCOUNTOFFICER'"
                    >
                      <div
                        :class="
                          path == '/admin/mo/psychology'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/psychology')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/psychology'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-head-snowflake-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Tes Psikologi</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/psychology'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Tes Psikologi
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="role == 'SUPERADMIN' || role == 'ACCOUNTOFFICER'"
                    >
                      <div
                        :class="
                          path == '/admin/mo/manage-client'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/manage-client')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/manage-client'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-account-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Klien</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/manage-client'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Klien
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="role != 'ACCOUNTOFFICER' && role != 'SUPERADMIN'"
                    >
                      <div
                        :class="
                          path == '/admin/mo/manage-customer'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/manage-customer')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/manage-customer'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-account-tie-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Customer</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/manage-customer'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Customer
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="role != 'ACCOUNTOFFICER' && role != 'SUPERADMIN'"
                      class="pa-2"
                    >
                      <div
                        :class="
                          path == '/admin/mo/setup-pendaftaran'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mo/setup-pendaftaran')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mo/setup-pendaftaran'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-clipboard-text-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Set Up Pendaftaran</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/setup-pendaftaran'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Set Up Pendaftaran
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="pa-2"
                      v-if="role != 'SUPERADMIN' && role != 'ACCOUNTOFFICER'"
                    >
                      <div
                        :class="
                          path == '/admin/mo/report/psychogram-template'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="
                          redirectTo('/admin/mo/report/psychogram-template')
                        "
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path ==
                                    '/admin/mo/report/psychogram-template'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-table</v-icon
                                >
                              </div>
                            </template>
                            <span>Psychogram Template</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/report/psychogram-template'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Psychogram Template
                        </div>
                      </div>
                    </div> -->
                    <div
                      class="pa-2"
                      v-if="
                        role != 'USER' &&
                        role != 'ASSOCIATE' &&
                        role != 'ACCOUNTOFFICER' &&
                        role != 'SUPERADMIN'
                      "
                    >
                      <div
                        :class="
                          path == '/admin/mo/report/manajemen-operasional'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="
                          redirectTo('/admin/mo/report/manajemen-operasional')
                        "
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path ==
                                    '/admin/mo/report/manajemen-operasional'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-chart-bell-curve</v-icon
                                >
                              </div>
                            </template>
                            <span>Report Operasional</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mo/report/manajemen-operasional'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Report Operasional
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
              <div
                v-if="role != 'SUPERADMIN'"
                :class="
                  sideItem.mk
                    ? 'active_item_sidebar_expanded flex-column'
                    : 'unactive_item_sidebar_expanded'
                "
              >
                <v-list-item
                  link
                  active-class="active_item_sidebar"
                  class="unactive_item_sidebar"
                  @click="changeState('mk')"
                >
                  <v-list-item-icon class="mr-0 pa-0">
                    <v-tooltip
                      nudge-right="15"
                      right
                      :disabled="drawer && !isMobile ? true : false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon
                            :class="
                              sideItem.mk
                                ? 'icon_unactive_sub'
                                : 'icon_unactive'
                            "
                            >mdi-account-group-outline</v-icon
                          >
                        </div>
                      </template>
                      <span>Manajemen Karyawan</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                    <v-list-item-title
                      :class="
                        sideItem.mk
                          ? 'txt_Sidebar_Active_Sub'
                          : 'txt_Sidebar_nonActive_Sub'
                      "
                      >Manajemen Karyawan</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-expand-transition>
                  <div class="sub_dir" v-if="sideItem.mk">
                    <div
                      class="pa-2"
                      v-if="role != 'USER' && role != 'SUPERADMIN'"
                    >
                      <div
                        v-if="role != 'USER'"
                        :class="
                          path ==
                          `/admin/mk/client-employee/employee/${klien_id}`
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="
                          redirectTo(
                            `/admin/mk/client-employee/employee/${klien_id}`
                          )
                        "
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path ==
                                    `/admin/mk/client-employee/employee/${klien_id}`
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-account-hard-hat</v-icon
                                >
                              </div>
                            </template>
                            <span>Data Karyawan</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path ==
                            `/admin/mk/client-employee/employee/${klien_id}`
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Data Karyawan
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="
                        (role == 'MASTERADMIN' || role == 'ADMIN') &&
                        role != 'SUPERADMIN'
                      "
                    >
                      <div
                        :class="
                          path == '/admin/mk/associate'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mk/associate')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mk/associate'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-account-multiple-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Associate</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mk/associate'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Associate
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="role != 'SUPERADMIN' && role != 'ACCOUNTOFFICER'"
                    >
                      <div
                        :class="
                          path == '/admin/mk/task-management'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mk/task-management')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mk/task-management'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-briefcase-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Manajemen Tugas</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mk/task-management'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Manajemen Tugas
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="role == 'MASTERADMIN' && role != 'SUPERADMIN'"
                    >
                      <div
                        :class="
                          path == '/admin/mk/salary'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mk/salary')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mk/salary'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  class="mx-1"
                                  small
                                >
                                  Rp
                                </v-icon>
                              </div>
                            </template>
                            <span>Gaji</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mk/salary'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Gaji
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="role != 'SUPERADMIN' && role != 'ACCOUNTOFFICER'"
                    >
                      <div
                        :class="
                          path ==
                          `/admin/mk/client-attendence/attendence/${klien_id}`
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="
                          redirectTo(
                            `/admin/mk/client-attendence/attendence/${klien_id}`
                          )
                        "
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path ==
                                    `/admin/mk/client-attendence/attendence/${klien_id}`
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-file-percent-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Kehadiran</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path ==
                            `/admin/mk/client-attendence/attendence/${klien_id}`
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Kehadiran
                        </div>
                      </div>
                    </div>
                    <div class="pa-2">
                      <div
                        :class="
                          path == '/admin/mk/chat-room'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mk/chat-room')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mk/chat-room'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-headset</v-icon
                                >
                              </div>
                            </template>
                            <span>Chat Room</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mk/chat-room'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Chat Room
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="
                        role != 'USER' &&
                        role != 'ASSOCIATE' &&
                        role != 'SUPERADMIN' &&
                        role != 'ACCOUNTOFFICER'
                      "
                    >
                      <div
                        :class="
                          path == '/admin/mk/report/manajemen-karyawan'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="
                          redirectTo('/admin/mk/report/manajemen-karyawan')
                        "
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path ==
                                    '/admin/mk/report/manajemen-karyawan'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-chart-line</v-icon
                                >
                              </div>
                            </template>
                            <span>Report Karyawan</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mk/report/manajemen-karyawan'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Report Karyawan
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
              <div
                v-if="
                  role != 'ACCOUNTOFFICER' &&
                  role != 'USER' &&
                  role != 'SUPERADMIN' &&
                  role != 'ASSOCIATE'
                "
                :class="
                  sideItem.mku
                    ? 'active_item_sidebar_expanded flex-column'
                    : 'unactive_item_sidebar_expanded'
                "
              >
                <v-list-item
                  link
                  active-class="active_item_sidebar"
                  class="unactive_item_sidebar"
                  >
                  <!-- @click="changeState('mku')" -->
                  <v-list-item-icon class="mr-0 pa-0">
                    <v-tooltip
                      nudge-right="15"
                      right
                      :disabled="drawer && !isMobile ? true : false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon
                            :class="
                              sideItem.mku
                                ? 'icon_unactive_sub'
                                : 'icon_unactive'
                            "
                            >mdi-wallet-outline</v-icon
                          >
                        </div>
                      </template>
                      <span>Manajemen Keuangan (Coming Soon)</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                    <v-list-item-title
                      :class="
                        sideItem.mku
                          ? 'txt_Sidebar_Active_Sub'
                          : 'txt_Sidebar_nonActive_Sub'
                      "
                      >
                      <div class="">
                        Manajemen Keuangan
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon 
                              color="#568280"
                              v-bind="attrs"
                              v-on="on"
                              size="17">
                              mdi-alert-circle
                            </v-icon>
                          </template>
                          <span>Coming Soon</span>
                        </v-tooltip>
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-expand-transition>
                  <div class="sub_dir" v-if="sideItem.mku">
                    <div class="px-2">
                      <div
                        :class="
                          path == '/admin/mku/invoice'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mku/invoice')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mku/invoice'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-file-table-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Invoice</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mku/invoice'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Invoice
                        </div>
                      </div>
                    </div>
                    <div class="pa-2">
                      <div
                        :class="
                          path == '/admin/mku/expenditure'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mku/expenditure')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mku/expenditure'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-database-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Expenditure</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mku/expenditure'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Expenditure
                        </div>
                      </div>
                    </div>
                    <div class="pa-2">
                      <div
                        :class="
                          path == '/admin/mku/accountancy'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/mku/accountancy')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/mku/accountancy'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-chart-pie</v-icon
                                >
                              </div>
                            </template>
                            <span>Accountancy</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/mku/accountancy'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Accountancy
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
              <div
                v-if="role != 'ACCOUNTOFFICER' && role != 'SUPERADMIN'"
                :class="
                  sideItem.cd
                    ? 'active_item_sidebar_expanded flex-column'
                    : 'unactive_item_sidebar_expanded'
                "
              >
                <v-list-item
                  link
                  active-class="active_item_sidebar"
                  class="unactive_item_sidebar"
                  @click="changeState('cd')"
                >
                  <v-list-item-icon class="mr-0 pa-0">
                    <v-tooltip
                      nudge-right="15"
                      right
                      :disabled="drawer && !isMobile ? true : false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon
                            :class="
                              sideItem.cd
                                ? 'icon_unactive_sub'
                                : 'icon_unactive'
                            "
                            >mdi-account-settings-outline</v-icon
                          >
                        </div>
                      </template>
                      <span>Community Development</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                    <v-list-item-title
                      :class="
                        sideItem.cd
                          ? 'txt_Sidebar_Active_Sub'
                          : 'txt_Sidebar_nonActive_Sub'
                      "
                      >Community Development</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-expand-transition>
                  <div class="sub_dir" v-if="sideItem.cd">
                    <div class="px-2">
                      <div
                        :class="
                          path == '/admin/cd/community-activities'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/cd/community-activities')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/cd/community-activities'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-file-table-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Community Activities</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/cd/community-activities'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Community Activities
                        </div>
                      </div>
                    </div>
                    <div class="pa-2">
                      <div
                        :class="
                          path == '/admin/cd/instansi-partner'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="redirectTo('/admin/cd/instansi-partner')"
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path == '/admin/cd/instansi-partner'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-office-building-outline</v-icon
                                >
                              </div>
                            </template>
                            <span>Instansi Partner</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/cd/instansi-partner'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Instansi Partner
                        </div>
                      </div>
                    </div>
                    <div
                      class="pa-2"
                      v-if="role != 'USER' && role != 'ASSOCIATE'"
                    >
                      <div
                        :class="
                          path == '/admin/cd/report/community-development'
                            ? drawer && !isMobile
                              ? 'dir_active d-flex align-center'
                              : 'dir_active_mobile'
                            : drawer && !isMobile
                            ? 'dir_item d-flex align-center'
                            : 'dir_item_mobile'
                        "
                        @click="
                          redirectTo('/admin/cd/report/community-development')
                        "
                      >
                        <div class="d-flex justify-center">
                          <v-tooltip
                            nudge-right="15"
                            right
                            :disabled="drawer && !isMobile ? true : false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  :class="
                                    path ==
                                    '/admin/cd/report/community-development'
                                      ? 'icon_active_sub'
                                      : 'icon_unactive_sub'
                                  "
                                  >mdi-chart-bar</v-icon
                                >
                              </div>
                            </template>
                            <span>Report Community</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="drawer && !isMobile"
                          :class="
                            path == '/admin/cd/report/community-development'
                              ? 'txt_Sidebar_m_Active ml-2'
                              : 'txt_Sidebar_m_Nonactive ml-2'
                          "
                        >
                          Report Community
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </div>
              <v-list-item
                active-class=" mb-0 active_item_sidebar"
                class="mb-0 unactive_item_sidebar"
                link
                to="/complaint"
                @click="changeState('co')"
              >
                <v-list-item-icon class="mr-0 pa-0">
                  <v-tooltip
                    nudge-right="15"
                    right
                    :disabled="drawer && !isMobile ? true : false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon
                          :class="
                            path == '/complaint'
                              ? 'icon_active'
                              : 'icon_unactive'
                          "
                          >mdi-file-send-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Pengaduan</span>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                  <v-list-item-title
                    :class="
                      path == '/complaint'
                        ? 'txt_Sidebar_Active'
                        : 'txt_Sidebar_nonActive'
                    "
                    >Pengaduan</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                active-class=" mb-0 active_item_sidebar"
                class="mb-0 unactive_item_sidebar"
                link
                to="/admin/act/activity"
                @click="changeState('act')"
                v-if="role == 'SUPERADMIN' || role == 'ACCOUNTOFFICER'"
              >
                <v-list-item-icon class="mr-0 pa-0">
                  <v-tooltip
                    nudge-right="15"
                    right
                    :disabled="drawer && !isMobile ? true : false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon
                          :class="
                            path == '/admin/act/activity'
                              ? 'icon_active'
                              : 'icon_unactive'
                          "
                          >mdi-history</v-icon
                        >
                      </div>
                    </template>
                    <span>Log Activity</span>
                  </v-tooltip>
                </v-list-item-icon>
                <v-list-item-content v-if="drawer && !isMobile" class="ml-2">
                  <v-list-item-title
                    :class="
                      path == '/admin/act/activity'
                        ? 'txt_Sidebar_Active'
                        : 'txt_Sidebar_nonActive'
                    "
                    >Log Activity</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
export default {
  name: "Sidebar",
  props: ["drawer"],
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.path = this.$route.path;
      let type = this.$route.params.type;
      if (this.path == "/") {
        for (let i in this.sideItem) {
          if (i == type) {
            this.sideItem[state] = true;
          } else {
            this.sideItem[i] = false;
          }
        }
      } else {
        this.changeState(type);
      }
    },
  },
  computed: {
    ...mapState({
      access: (state) => state.user.my_access,
      role: (state) => state.role,
      isMobile: (state) => state.isMobile,
      profile: (state) => state.profile,
    }),
    hider: {
      get: () => {
        return this.drawer;
      },
      set: () => {
        return this.drawer;
      },
    },
  },
  mounted() {
    this.path = this.$route.path;
    let type = this.$route.params.type;
    if (this.path == "/") {
      for (let i in this.sideItem) {
        if (i == type) {
          this.sideItem[state] = true;
        } else {
          this.sideItem[i] = false;
        }
      }
    } else {
      this.changeState(type);
    }
    let profile = setInterval(() => {
      if (this.profile) {
        this.klien_id = this.profile.data.profile.klien_id;
        clearInterval(profile);
      }
    }, 300);
  },
  data() {
    return {
      sidebarMenu: true,
      change: false,
      path: "",
      otherMenu: false,
      klien_id: 0,
      sideItem: {
        db: false,
        ao: false,
        mo: false,
        mk: false,
        mku: false,
        co: false,
        cd: false,
        setup: false,
        act: false,
      },
    };
  },
  methods: {
    logout() {
      Cookies.remove("token");
      Cookies.remove("role");
      this.$router.go("/auth/Login");
    },
    mutateSide() {
      this.$store.commit("MUTATE_DRAWER", !this.drawer);
    },
    changeState(state, single) {
      for (let i in this.sideItem) {
        if (i == state) {
          this.sideItem[state] = true;
        } else {
          this.sideItem[i] = false;
        }
      }
      if (single) {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$store.commit("MUTATE_DRAWER", false);
        }
      }
    },
    redirectTo(path) {
      this.$router.push(path);
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$store.commit("MUTATE_DRAWER", false);
      }
    },
  },
};
</script>

<style>
.v-list-item__icon {
  min-width: 10px !important;
  margin-left: 0 !important;
}
.v-list-item--link::before {
  background: none !important;
}
.active_item_sidebar {
  background-color: #052633 !important;
  border-radius: 8px;
}
.unactive_item_sidebar {
  background: transparent;
  justify-content: center;
}
.active_item_sidebar_expanded {
  background-color: white !important;
  border-radius: 8px;
  color: #fff !important;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.1);
}
.unactive_item_sidebar_expanded {
  background: transparent;
  justify-content: center;
}
.bg-head {
  background: #e1e1e1;
}
.bg {
  background: #0e1614;
}
._avatar {
  object-fit: cover;
}

.text-small {
  font-size: small;
}
.sub_menu {
  margin-left: 35px;
}
.txt_Sidebar_nonActive {
  color: #568280 !important;
  font-weight: normal !important;
}
.txt_Sidebar_Active {
  color: white;
  font-weight: bold !important;
}
.txt_Sidebar_nonActive_Sub {
  color: #568280 !important;
  font-weight: normal !important;
}
.txt_Sidebar_Active_Sub {
  color: #052633;
  font-weight: bold !important;
}
.txt_Sidebar_m_Active {
  color: white;
  font-weight: bold !important;
  font-size: small;
}
.txt_Sidebar_m_Nonactive {
  color: #052633;
  font-weight: normal !important;
  font-size: small;
}
.sub_dir {
  height: 100%;
  /* background: #e6f4ec; */
  /* padding: 5px;
  border-radius: 10px; */
}
.dir_item {
  padding: 5px 5px 5px 30px;
  border-radius: 10px;
  background: white;
  cursor: pointer;
}
.dir_active {
  padding: 5px 5px 5px 30px;
  border-radius: 10px;
  background: #052633;
  cursor: pointer;
}
.dir_item_mobile {
  padding: 3px;
  border-radius: 10px;
  background: white;
  cursor: pointer;
}
.dir_active_mobile {
  padding: 3px;
  border-radius: 10px;
  background: #052633;
  cursor: pointer;
}
.icon_active {
  color: white !important;
}
.icon_unactive {
  color: #568280 !important;
}
.icon_active_sub {
  color: white !important;
}
.icon_unactive_sub {
  color: black !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
