<template>
  <div class="mt-3" style="width: 400px">
    <v-card style="overflow: hidden" flat rounded="0">
      <div
        class="d-flex align-center"
        v-for="(item, n) in selected.data"
        :key="n"
      >
        <div
          class="pa-3"
          :style="
            n <= selected.data.length - 1 && n != 0
              ? 'margin-top: 1px; width: 40%;background:#CDD0D1'
              : 'width: 40%; background:#CDD0D1'
          "
        >
          {{ item.label }}
        </div>
        <div
          class="pl-4"
          :style="
            n >= selected.data.length - 1 ? 'margin-top: 1px; width: 40%' : ''
          "
        >
          {{ item.status == "show" ? "Filled" : "Hide" }}
        </div>
      </div>
    </v-card>
    <div class="full_w mt-3">
      <h4>Link Form untuk customer</h4>
      <v-text-field
        readonly
        :value="selected.link_daftar"
        placeholder="link form untuk customer"
        color="#0D47A1"
        dense
        hide-details
        outlined
        class="rounded"
        append-icon="mdi-content-copy"
        @click:append="copyLink(selected.link_daftar)"
      ></v-text-field>
    </div>
    <div class="d-flex justify-space-between mt-2">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="$emit('close')"
        dark
        >Tutup</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "createOfficer",
  props: ["selected"],
  data() {
    return {
      loading: false,
    };
  },
  mounted() {},
  methods: {
    copyLink(link) {
      navigator.clipboard.writeText(link);
      this.$store.dispatch("notify", {
        msg: "Copied",
        type: "success",
      });
    },
  },
};
</script>

<style scoped></style>
