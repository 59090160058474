<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama Klien</b></p>
          <v-text-field
            readonly
            filled
            placeholder="Silakan isi nama di sini"
            v-model="selected.event.customer"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt">
            <b>Tipe Klien</b>
          </p>
          <v-text-field
            readonly
            filled
            placeholder="Silakan isi tipe klien di sini"
            v-model="selected.event.tipe"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Tanggal Terakhir</b></p>
          <div v-for="(item, n) in selected.event.timeline" :key="n">
            <v-menu
              disabled
              v-if="selected.data.progres == item.id"
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="item.end_date"
                  prepend-inner-icon="mdi-calendar-range"
                  readonly
                  filled
                  placeholder="Silakan isi tanggal berlaku di sini"
                  hide-details
                  dense
                  v-on="on"
                  outlined
                  class="rounded-lg"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.end_date"
                @input="closeDateMenu(item.end_date)"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Timeline</b></p>
          <div v-for="(item, n) in selected.event.timeline" :key="n">
            <v-text-field
              v-if="selected.data.progres == item.id"
              readonly
              filled
              placeholder="Silakan isi alamat di sini"
              v-model="item.step"
              color="#0D47A1"
              dense
              hide-details
              outlined
              class="rounded-lg mb-2"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Status</b></p>
          <div v-for="(item, n) in selected.event.timeline" :key="n">
            <v-radio-group
              v-if="selected.data.progres == item.id"
              v-model="item.status"
              hide-details
              class="ma-0"
              dense
              row
            >
              <v-radio
                label="Sudah Dikerjakan"
                color="#006341"
                value="1"
              ></v-radio>
              <v-radio
                label="Belum Dikerjakan"
                color="#006341"
                value="0"
              ></v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex align-center justify-end mt-2">
      <v-btn
        outlined
        depressed
        color="#BD9028"
        class="text-capitalize mr-2"
        @click="$emit('close')"
        dark
      >
        <h4>Batal</h4>
      </v-btn>
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="updateData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "createClient",
  props: ["selected"],
  data() {
    return {
      role: "",
      loading: false,
      menuDate: false,
    };
  },
  mounted() {
    console.log(this.selected);
  },
  methods: {
    closeDateMenu(item) {
      this.menuDate = false;
      this.selected.data.tgl_end == ""
        ? this.$date(item).format("dddd, MMMM Do YYYY")
        : "";
    },
    updateData() {
      this.loading = true;
      console.log(this.selected.event.timeline[this.selected.data.idx]);
      let data = {
        body: {
          id: this.selected.data.progres,
          status: this.selected.event.timeline[this.selected.data.idx].status,
        },
        path: `sysadmin/event/status`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.code == 200) {
            this.$emit("success");
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else if (data.code == 201) {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
  },
};
</script>
