<template>
  <div class="d-flex align-center">
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mdAndDown && $route.path !== '/'"
      class="mr-2"
      @click="mutateSide"
      style="color: #568280"
    >
    </v-app-bar-nav-icon>
    <breadcrumbs :items="items" />
    <v-spacer></v-spacer>
    <div
      v-if="
        notification.length && $route.path !== '/notifications' && $route.path !== '/'
      "
    >
      <v-menu
        offset-y
        nudge-bottom="10px"
        max-width="300px"
        min-width="300px"
        :close-on-content-click="false"
        v-model="notifyMenu"
        rounded="lg"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            color="red"
            :content="arrIsReadNotif.unread"
            :value="arrIsReadNotif.unread"
            overlap
          >
            <v-btn @click="getNotif" v-bind="attrs" v-on="on" small icon>
              <v-icon color="black">mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>

        <div class="pa-4 white">
          <div class="d-flex align-center justify-space-between">
            <h3>Notifikasi</h3>
            <div 
              @click="markAllReaded"
              class="d-flex align-center" 
              style="cursor: pointer"
              >
              <v-icon color="#106967" class="mr-1">mdi-check-all</v-icon>
              <h5 class="not_bold" style="color: #106967">Mark all as read</h5>
            </div>
          </div>
          <div class="my-3">
            <v-btn
              class="mr-2 text-capitalize"
              rounded
              depressed
              :color="!Unread ? '#CFE1E1' : ''"
              :dark="!Unread ? true : false"
              @click="(Unread = false), getNotif()"
            >
              <b :style="`color:${!Unread ? '#106967' : 'black'}`"> All </b>
            </v-btn>
            <v-btn
              :color="Unread? '#CFE1E1' : ''"
              class="text-capitalize"
              rounded
              @click="(Unread = true), getNotif()"
              depressed
            >
              <b :style="`color:${Unread ? '#106967' : 'black'}`">
                Unread
              </b>
            </v-btn>
          </div>
          <content-notif
            :isUnRead="Unread"
            v-if="!loadNotif"
            @get="getNotif"
            @close="notifyMenu = false"
          />
          <div v-if="loadNotif">
            <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
          </div>

          <div class="mb-2">
            <b
              class="small_txt"
              style="cursor: pointer; color: #106967"
              @click="toNotif"
              >View All Notification</b
            >
          </div>
        </div>
      </v-menu>
    </div>
    <v-badge
      v-if="!notification.length && $route.path !== '/'"
      color="red"
      :content="0"
      :value="0"
      overlap
    >
      <v-btn disabled small icon class="ml-2">
        <v-icon color="black">mdi-bell-outline</v-icon>
      </v-btn>
    </v-badge>
    <v-menu
      v-if="profile && $route.path !== '/'"
      offset-y
      nudge-bottom="10px"
      min-width="200px"
      max-width="300px"
      rounded="lg"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="ml-4 d-flex align-center">
          <v-avatar dark size="35">
            <img
              :src="
                profile.data.profile.avatar ? profile.data.profile.avatar : ava
              "
              alt="avatar"
              class="obj_fit"
            />
          </v-avatar>
        </div>
      </template>
      <v-list class="px-3">
        <v-list-item>
          <v-list-item-title>
            <div class="d-flex align-center pt-2">
              <v-avatar class="mr-3">
                <img
                  :src="
                    profile.data.profile.avatar
                      ? profile.data.profile.avatar
                      : ava
                  "
                  class="obj_fit"
                />
              </v-avatar>
              <div>
                <p class="ma-0 pa-0 txt_color_default">
                  <b class="text-capitalize">
                    {{
                      profile.data.profile.nama
                        ? profile.data.profile.nama.split(" ")[0]
                        : "-"
                    }}
                  </b>
                </p>
                <p class="ma-0 pa-0" style="color: #9ba0a3">
                  {{ profile.data.account.email }}
                </p>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-list-item :to="`/admin/profile`">
          <v-list-item-title class="txt_color_default">
            <h4>Lihat Profile</h4>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>
            <div class="d-flex align-center txt_color_default">
              <v-icon class="mr-2" color="#052633">mdi-exit-to-app</v-icon>
              <h4>Logout</h4>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import contentNotif from "../../views/Notifikasi/contentNotif.vue";
import Breadcrumbs from "../breadcrumbs/breadcrumbs.vue";
export default {
  components: { contentNotif, Breadcrumbs },
  props: ["items"],
  name: "navbarProfile",
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
      ava: (state) => state.ava,
      profile: (state) => state.profile,
      notification: (state) => state.notification,
      notificationUnRead: (state) => state.notificationUnRead,
      arrIsReadNotif: (state) => state.arrIsReadNotif,
    }),
  },
  created() {},

  data() {
    return {
      Unread: false,
      toggler: true,
      notifyMenu: false,
      loadNotif: false,
    };
  },
  mounted() {
    let profile = setInterval(() => {
      if (this.profile) {
        // fungsi web socket
        // window.Echo.channel(
        //   `notify${this.profile.data.profile.klien_id}`
        // ).listen("NotificationEvent", (event) => {
        //   if (event) {
        //     this.getNotif();
        //     if (
        //       this.profile.data.account.id == event.data.to_user_id &&
        //       event.type == "send"
        //     ) {
        //       this.$store.dispatch("notify", {
        //         msg: event.data.message,
        //         type: "info",
        //       });
        //     }
        //   }
        // });
        // this.getNotif();
        // fungsi web socket

        clearInterval(profile);
      }
    }, 300);
  },
  methods: {
    mutateSide() {
      if (this.toggler == this.drawer) {
        this.toggler = !this.toggler;
      }
      this.$store.commit("MUTATE_DRAWER", this.toggler);
    },
    logout() {
      this.$store.dispatch("logout", {});
    },
    toNotif() {
      this.notifyMenu = false;
      this.$router.push("/notifications");
    },
    getNotif() {
      this.loadNotif = true;
      
      setTimeout(
        function () { 
          this.loadNotif = false;
        }.bind(this)
      , 300);
    },
    markAllReaded() {
      this.loading = true;
      let data = {
        path: `sysadmin/notif/read-all`,
        body: "",
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.getNotif();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });

          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
