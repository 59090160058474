import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/Dayjs";
import "./assets/css/style.css";
import vuetify from "./plugins/vuetify";
import "./plugins/component";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import interceptorsSetup from "./interceptor";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Cookies from "js-cookie";
import db from "../src/firebase";
const options = {
  timeout: 5000,
};
import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;
interceptorsSetup();
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.component("date-picker", DatePicker);
Vue.use(Toast, options);
Vue.component("calendar", Calendar);
Vue.use(VueClipboard);
// ========================================================================== CONFIG WEB SOCKET

// window.Pusher = require("pusher-js");
// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: process.env.VUE_APP_WEBSOCKET_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
//   wsPort: 6001,
//   wssPort: 6001,
//   disableStats: true,
// });

// fungsi web socket
// import Echo from "laravel-echo";
// import {required} from "vuelidate/lib/validators";
// window.io = require("socket.io-client");
// window.Echo = new Echo({
//   broadcaster: "socket.io",
//   host: `${process.env.VUE_APP_ENV}`,
// });
// fungsi web socket

// host: "https://dev-biro.gamifindo.com",
// config socket io end

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
