var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-1"},[_c('v-row',{attrs:{"no-gutters":"","align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"pa-3 d-flex align-start justify-center",staticStyle:{"height":"auto"}},[(_vm.drawer && !_vm.isMobile)?_c('v-img',{attrs:{"src":require("../../assets/icon/Logo.png"),"width":"45px","height":"30px","contain":""}}):_vm._e(),(!_vm.drawer || _vm.isMobile)?_c('v-img',{attrs:{"src":require("../../assets/icon/logo_small.png"),"width":"50px","height":"30px","contain":""}}):_vm._e()],1)]),(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"pa-3 d-flex align-start justify-center",staticStyle:{"height":"8vh"}},[_c('v-app-bar-nav-icon',{staticStyle:{"color":"#568280"},on:{"click":_vm.mutateSide}})],1)]):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":"","align":"start","justify":_vm.isMobile ? 'center' : 'start'}},[_c('v-col',{attrs:{"cols":!_vm.drawer || _vm.isMobile ? 8 : 12}},[_c('div',{class:_vm.drawer && !_vm.isMobile ? 'px-1' : 'px-1'},[_c('v-list',{staticClass:"px-2 rounded-lg",staticStyle:{"background-color":"#cfe1e1"},attrs:{"dense":""}},[_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"active-class":"active_item_sidebar","link":"","to":"/"},on:{"click":function($event){return _vm.changeState('db', 'single')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/' ? 'icon_active' : 'icon_unactive'},[_vm._v(" mdi-home-outline ")])],1)]}}])},[_c('span',[_vm._v("Dashboard")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.path == '/'
                      ? 'txt_Sidebar_Active'
                      : 'txt_Sidebar_nonActive'},[_vm._v("Dashboard")])],1):_vm._e()],1),(_vm.role == 'SUPERADMIN')?_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"active-class":"active_item_sidebar","link":"","to":"/admin/ao/account-officer"},on:{"click":function($event){return _vm.changeState('ao')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.sideItem.ao ? 'icon_active' : 'icon_unactive'},[_vm._v("mdi-book-account-outline")])],1)]}}],null,false,2711142958)},[_c('span',[_vm._v("Account Officer")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.sideItem.ao
                      ? 'txt_Sidebar_Active'
                      : 'txt_Sidebar_nonActive'},[_vm._v("Account Officer")])],1):_vm._e()],1):_vm._e(),(_vm.role == 'SUPERADMIN')?_c('div',{class:_vm.sideItem.mo
                  ? 'active_item_sidebar_expanded flex-column'
                  : 'unactive_item_sidebar_expanded'},[_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"link":"","active-class":"active_item_sidebar"},on:{"click":function($event){return _vm.changeState('mo')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.sideItem.mo
                              ? 'icon_unactive_sub'
                              : 'icon_unactive'},[_vm._v("mdi-chart-bar")])],1)]}}],null,false,3106459354)},[_c('span',[_vm._v("Biro Analytics")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.sideItem.mo
                        ? 'txt_Sidebar_Active_Sub'
                        : 'txt_Sidebar_nonActive_Sub'},[_vm._v("Biro Analytics")])],1):_vm._e()],1),_c('v-expand-transition',[(_vm.sideItem.mo)?_c('div',{staticClass:"sub_dir"},[_c('div',{staticClass:"px-2"},[_c('div',{class:_vm.path == '/admin/mo/rekap-client'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/rekap-client')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/rekap-client'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-chart-line")])],1)]}}],null,false,3491575313)},[_c('span',[_vm._v("Per Klien")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/rekap-client'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Per Klien ")]):_vm._e()])]),_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mo/rekap-all-client'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/rekap-all-client')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/rekap-all-client'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-chart-multiple")])],1)]}}],null,false,831076003)},[_c('span',[_vm._v("Semua Klien")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/rekap-all-client'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Semua Klien ")]):_vm._e()])])]):_vm._e()])],1):_vm._e(),(_vm.role == 'SUPERADMIN')?_c('div',{class:_vm.sideItem.setup
                  ? 'active_item_sidebar_expanded flex-column'
                  : 'unactive_item_sidebar_expanded'},[_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"link":"","active-class":"active_item_sidebar"},on:{"click":function($event){return _vm.changeState('setup')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.sideItem.setup
                              ? 'icon_unactive_sub'
                              : 'icon_unactive'},[_vm._v("mdi-file-cog")])],1)]}}],null,false,3006653359)},[_c('span',[_vm._v("Setup")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.sideItem.setup
                        ? 'txt_Sidebar_Active_Sub'
                        : 'txt_Sidebar_nonActive_Sub'},[_vm._v("Setup")])],1):_vm._e()],1),_c('v-expand-transition',[(_vm.sideItem.setup)?_c('div',{staticClass:"sub_dir"},[_c('div',{staticClass:"px-2"},[_c('div',{class:_vm.path == '/admin/setup/setup-form-pendaftaran'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/setup/setup-form-pendaftaran')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/setup/setup-form-pendaftaran'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-file-arrow-left-right-outline")])],1)]}}],null,false,3423182023)},[_c('span',[_vm._v("Setup Form Pendaftaran")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/setup/setup-form-pendaftaran'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Setup Form Pendaftaran ")]):_vm._e()])]),_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/setup/setup-event-timeline'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/setup/setup-event-timeline')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/setup/setup-event-timeline'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-calendar-edit")])],1)]}}],null,false,2245084497)},[_c('span',[_vm._v("Setup Timeline Event")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/setup/setup-event-timeline'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Setup Timeline Event ")]):_vm._e()])])]):_vm._e()])],1):_vm._e(),(_vm.role != 'SUPERADMIN')?_c('div',{class:_vm.sideItem.mo
                  ? 'active_item_sidebar_expanded flex-column'
                  : 'unactive_item_sidebar_expanded'},[_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"link":"","active-class":"active_item_sidebar"},on:{"click":function($event){return _vm.changeState('mo')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.sideItem.mo
                              ? 'icon_unactive_sub'
                              : 'icon_unactive'},[_vm._v("mdi-cog-outline")])],1)]}}],null,false,2011763628)},[_c('span',[_vm._v("Manajemen Operasional")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.sideItem.mo
                        ? 'txt_Sidebar_Active_Sub'
                        : 'txt_Sidebar_nonActive_Sub'},[_vm._v("Manajemen Operasional")])],1):_vm._e()],1),_c('v-expand-transition',[(_vm.sideItem.mo)?_c('div',{staticClass:"sub_dir"},[(_vm.role != 'ACCOUNTOFFICER' && _vm.role != 'SUPERADMIN')?_c('div',{staticClass:"px-2"},[_c('div',{class:_vm.path == '/admin/mo/event'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/event')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/event'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-text-box")])],1)]}}],null,false,183173358)},[_c('span',[_vm._v("Manajemen Event")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/event'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Manajemen Event ")]):_vm._e()])]):_vm._e(),(_vm.role != 'SUPERADMIN')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mo/schedule'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/schedule')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/schedule'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-calendar-account")])],1)]}}],null,false,2689664860)},[_c('span',[_vm._v("Schedule")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/schedule'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Schedule ")]):_vm._e()])]):_vm._e(),(_vm.role != 'SUPERADMIN' && _vm.role != 'ACCOUNTOFFICER')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mo/all-document'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/all-document')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/all-document'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-file-document-multiple-outline")])],1)]}}],null,false,2668606000)},[_c('span',[_vm._v("All Document")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/all-document'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" All Document ")]):_vm._e()])]):_vm._e(),(_vm.role != 'SUPERADMIN' && _vm.role != 'ACCOUNTOFFICER')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mo/psychology'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/psychology')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/psychology'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-head-snowflake-outline")])],1)]}}],null,false,1441718412)},[_c('span',[_vm._v("Tes Psikologi")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/psychology'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Tes Psikologi ")]):_vm._e()])]):_vm._e(),(_vm.role == 'SUPERADMIN' || _vm.role == 'ACCOUNTOFFICER')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mo/manage-client'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/manage-client')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/manage-client'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-account-outline")])],1)]}}],null,false,2470572414)},[_c('span',[_vm._v("Klien")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/manage-client'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Klien ")]):_vm._e()])]):_vm._e(),(_vm.role != 'ACCOUNTOFFICER' && _vm.role != 'SUPERADMIN')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mo/manage-customer'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/manage-customer')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/manage-customer'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-account-tie-outline")])],1)]}}],null,false,3791302422)},[_c('span',[_vm._v("Customer")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/manage-customer'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Customer ")]):_vm._e()])]):_vm._e(),(_vm.role != 'ACCOUNTOFFICER' && _vm.role != 'SUPERADMIN')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mo/setup-pendaftaran'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/setup-pendaftaran')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mo/setup-pendaftaran'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-clipboard-text-outline")])],1)]}}],null,false,4231163660)},[_c('span',[_vm._v("Set Up Pendaftaran")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/setup-pendaftaran'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Set Up Pendaftaran ")]):_vm._e()])]):_vm._e(),(
                      _vm.role != 'USER' &&
                      _vm.role != 'ASSOCIATE' &&
                      _vm.role != 'ACCOUNTOFFICER' &&
                      _vm.role != 'SUPERADMIN'
                    )?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mo/report/manajemen-operasional'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mo/report/manajemen-operasional')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path ==
                                  '/admin/mo/report/manajemen-operasional'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-chart-bell-curve")])],1)]}}],null,false,1952677000)},[_c('span',[_vm._v("Report Operasional")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mo/report/manajemen-operasional'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Report Operasional ")]):_vm._e()])]):_vm._e()]):_vm._e()])],1):_vm._e(),(_vm.role != 'SUPERADMIN')?_c('div',{class:_vm.sideItem.mk
                  ? 'active_item_sidebar_expanded flex-column'
                  : 'unactive_item_sidebar_expanded'},[_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"link":"","active-class":"active_item_sidebar"},on:{"click":function($event){return _vm.changeState('mk')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.sideItem.mk
                              ? 'icon_unactive_sub'
                              : 'icon_unactive'},[_vm._v("mdi-account-group-outline")])],1)]}}],null,false,2431084752)},[_c('span',[_vm._v("Manajemen Karyawan")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.sideItem.mk
                        ? 'txt_Sidebar_Active_Sub'
                        : 'txt_Sidebar_nonActive_Sub'},[_vm._v("Manajemen Karyawan")])],1):_vm._e()],1),_c('v-expand-transition',[(_vm.sideItem.mk)?_c('div',{staticClass:"sub_dir"},[(_vm.role != 'USER' && _vm.role != 'SUPERADMIN')?_c('div',{staticClass:"pa-2"},[(_vm.role != 'USER')?_c('div',{class:_vm.path ==
                        ("/admin/mk/client-employee/employee/" + _vm.klien_id)
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo(
                          ("/admin/mk/client-employee/employee/" + _vm.klien_id)
                        )}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path ==
                                  ("/admin/mk/client-employee/employee/" + _vm.klien_id)
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-account-hard-hat")])],1)]}}],null,false,1006513611)},[_c('span',[_vm._v("Data Karyawan")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path ==
                          ("/admin/mk/client-employee/employee/" + _vm.klien_id)
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Data Karyawan ")]):_vm._e()]):_vm._e()]):_vm._e(),(
                      (_vm.role == 'MASTERADMIN' || _vm.role == 'ADMIN') &&
                      _vm.role != 'SUPERADMIN'
                    )?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mk/associate'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mk/associate')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mk/associate'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-account-multiple-outline")])],1)]}}],null,false,751249190)},[_c('span',[_vm._v("Associate")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mk/associate'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Associate ")]):_vm._e()])]):_vm._e(),(_vm.role != 'SUPERADMIN' && _vm.role != 'ACCOUNTOFFICER')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mk/task-management'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mk/task-management')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mk/task-management'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-briefcase-outline")])],1)]}}],null,false,3481648371)},[_c('span',[_vm._v("Manajemen Tugas")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mk/task-management'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Manajemen Tugas ")]):_vm._e()])]):_vm._e(),(_vm.role == 'MASTERADMIN' && _vm.role != 'SUPERADMIN')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mk/salary'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mk/salary')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mx-1",class:_vm.path == '/admin/mk/salary'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub',attrs:{"small":""}},[_vm._v(" Rp ")])],1)]}}],null,false,3613527492)},[_c('span',[_vm._v("Gaji")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mk/salary'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Gaji ")]):_vm._e()])]):_vm._e(),(_vm.role != 'SUPERADMIN' && _vm.role != 'ACCOUNTOFFICER')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path ==
                        ("/admin/mk/client-attendence/attendence/" + _vm.klien_id)
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo(
                          ("/admin/mk/client-attendence/attendence/" + _vm.klien_id)
                        )}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path ==
                                  ("/admin/mk/client-attendence/attendence/" + _vm.klien_id)
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-file-percent-outline")])],1)]}}],null,false,3162255605)},[_c('span',[_vm._v("Kehadiran")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path ==
                          ("/admin/mk/client-attendence/attendence/" + _vm.klien_id)
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Kehadiran ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mk/chat-room'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mk/chat-room')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mk/chat-room'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-headset")])],1)]}}],null,false,2747313317)},[_c('span',[_vm._v("Chat Room")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mk/chat-room'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Chat Room ")]):_vm._e()])]),(
                      _vm.role != 'USER' &&
                      _vm.role != 'ASSOCIATE' &&
                      _vm.role != 'SUPERADMIN' &&
                      _vm.role != 'ACCOUNTOFFICER'
                    )?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mk/report/manajemen-karyawan'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mk/report/manajemen-karyawan')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path ==
                                  '/admin/mk/report/manajemen-karyawan'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-chart-line")])],1)]}}],null,false,3653075960)},[_c('span',[_vm._v("Report Karyawan")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mk/report/manajemen-karyawan'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Report Karyawan ")]):_vm._e()])]):_vm._e()]):_vm._e()])],1):_vm._e(),(
                _vm.role != 'ACCOUNTOFFICER' &&
                _vm.role != 'USER' &&
                _vm.role != 'SUPERADMIN' &&
                _vm.role != 'ASSOCIATE'
              )?_c('div',{class:_vm.sideItem.mku
                  ? 'active_item_sidebar_expanded flex-column'
                  : 'unactive_item_sidebar_expanded'},[_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"link":"","active-class":"active_item_sidebar"}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.sideItem.mku
                              ? 'icon_unactive_sub'
                              : 'icon_unactive'},[_vm._v("mdi-wallet-outline")])],1)]}}],null,false,1039047249)},[_c('span',[_vm._v("Manajemen Keuangan (Coming Soon)")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.sideItem.mku
                        ? 'txt_Sidebar_Active_Sub'
                        : 'txt_Sidebar_nonActive_Sub'},[_c('div',{},[_vm._v(" Manajemen Keuangan "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#568280","size":"17"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,false,2256496047)},[_c('span',[_vm._v("Coming Soon")])])],1)])],1):_vm._e()],1),_c('v-expand-transition',[(_vm.sideItem.mku)?_c('div',{staticClass:"sub_dir"},[_c('div',{staticClass:"px-2"},[_c('div',{class:_vm.path == '/admin/mku/invoice'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mku/invoice')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mku/invoice'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-file-table-outline")])],1)]}}],null,false,298725215)},[_c('span',[_vm._v("Invoice")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mku/invoice'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Invoice ")]):_vm._e()])]),_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mku/expenditure'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mku/expenditure')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mku/expenditure'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-database-outline")])],1)]}}],null,false,1514502979)},[_c('span',[_vm._v("Expenditure")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mku/expenditure'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Expenditure ")]):_vm._e()])]),_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/mku/accountancy'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/mku/accountancy')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/mku/accountancy'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-chart-pie")])],1)]}}],null,false,794866847)},[_c('span',[_vm._v("Accountancy")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/mku/accountancy'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Accountancy ")]):_vm._e()])])]):_vm._e()])],1):_vm._e(),(_vm.role != 'ACCOUNTOFFICER' && _vm.role != 'SUPERADMIN')?_c('div',{class:_vm.sideItem.cd
                  ? 'active_item_sidebar_expanded flex-column'
                  : 'unactive_item_sidebar_expanded'},[_c('v-list-item',{staticClass:"unactive_item_sidebar",attrs:{"link":"","active-class":"active_item_sidebar"},on:{"click":function($event){return _vm.changeState('cd')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.sideItem.cd
                              ? 'icon_unactive_sub'
                              : 'icon_unactive'},[_vm._v("mdi-account-settings-outline")])],1)]}}],null,false,1917146891)},[_c('span',[_vm._v("Community Development")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.sideItem.cd
                        ? 'txt_Sidebar_Active_Sub'
                        : 'txt_Sidebar_nonActive_Sub'},[_vm._v("Community Development")])],1):_vm._e()],1),_c('v-expand-transition',[(_vm.sideItem.cd)?_c('div',{staticClass:"sub_dir"},[_c('div',{staticClass:"px-2"},[_c('div',{class:_vm.path == '/admin/cd/community-activities'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/cd/community-activities')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/cd/community-activities'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-file-table-outline")])],1)]}}],null,false,2960691183)},[_c('span',[_vm._v("Community Activities")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/cd/community-activities'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Community Activities ")]):_vm._e()])]),_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/cd/instansi-partner'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/cd/instansi-partner')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/cd/instansi-partner'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-office-building-outline")])],1)]}}],null,false,616983170)},[_c('span',[_vm._v("Instansi Partner")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/cd/instansi-partner'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Instansi Partner ")]):_vm._e()])]),(_vm.role != 'USER' && _vm.role != 'ASSOCIATE')?_c('div',{staticClass:"pa-2"},[_c('div',{class:_vm.path == '/admin/cd/report/community-development'
                          ? _vm.drawer && !_vm.isMobile
                            ? 'dir_active d-flex align-center'
                            : 'dir_active_mobile'
                          : _vm.drawer && !_vm.isMobile
                          ? 'dir_item d-flex align-center'
                          : 'dir_item_mobile',on:{"click":function($event){return _vm.redirectTo('/admin/cd/report/community-development')}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path ==
                                  '/admin/cd/report/community-development'
                                    ? 'icon_active_sub'
                                    : 'icon_unactive_sub'},[_vm._v("mdi-chart-bar")])],1)]}}],null,false,3130862580)},[_c('span',[_vm._v("Report Community")])])],1),(_vm.drawer && !_vm.isMobile)?_c('div',{class:_vm.path == '/admin/cd/report/community-development'
                            ? 'txt_Sidebar_m_Active ml-2'
                            : 'txt_Sidebar_m_Nonactive ml-2'},[_vm._v(" Report Community ")]):_vm._e()])]):_vm._e()]):_vm._e()])],1):_vm._e(),_c('v-list-item',{staticClass:"mb-0 unactive_item_sidebar",attrs:{"active-class":" mb-0 active_item_sidebar","link":"","to":"/complaint"},on:{"click":function($event){return _vm.changeState('co')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/complaint'
                            ? 'icon_active'
                            : 'icon_unactive'},[_vm._v("mdi-file-send-outline")])],1)]}}])},[_c('span',[_vm._v("Pengaduan")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.path == '/complaint'
                      ? 'txt_Sidebar_Active'
                      : 'txt_Sidebar_nonActive'},[_vm._v("Pengaduan")])],1):_vm._e()],1),(_vm.role == 'SUPERADMIN' || _vm.role == 'ACCOUNTOFFICER')?_c('v-list-item',{staticClass:"mb-0 unactive_item_sidebar",attrs:{"active-class":" mb-0 active_item_sidebar","link":"","to":"/admin/act/activity"},on:{"click":function($event){return _vm.changeState('act')}}},[_c('v-list-item-icon',{staticClass:"mr-0 pa-0"},[_c('v-tooltip',{attrs:{"nudge-right":"15","right":"","disabled":_vm.drawer && !_vm.isMobile ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{class:_vm.path == '/admin/act/activity'
                            ? 'icon_active'
                            : 'icon_unactive'},[_vm._v("mdi-history")])],1)]}}],null,false,1802157692)},[_c('span',[_vm._v("Log Activity")])])],1),(_vm.drawer && !_vm.isMobile)?_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{class:_vm.path == '/admin/act/activity'
                      ? 'txt_Sidebar_Active'
                      : 'txt_Sidebar_nonActive'},[_vm._v("Log Activity")])],1):_vm._e()],1):_vm._e()],1)],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }