<template>
  <div>
    <v-stepper v-model="step" class="elevation-0" v-if="inventory.type == 'c'">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-card elevation="0" class="pa-2" flat>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="small_txt ma-0"><b>Nama</b></p>
                <v-text-field
                  placeholder="Silakan isi nama di sini"
                  v-model="inventory.body.nama"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt ma-0"><b>Username</b></p>
                <v-text-field
                  placeholder="Silakan isi nama di sini"
                  v-model="inventory.body.username"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt ma-0">
                  <b>No. Telepon</b>
                </p>
                <v-text-field
                  v-model="inventory.body.telepon"
                  placeholder="Silakan isi no. telepon di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt ma-0"><b>NIP</b></p>
                <v-text-field
                  v-model="inventory.body.nip"
                  placeholder="Silakan isi nip di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt ma-0">
                  <b>No. SIP</b>
                </p>
                <v-text-field
                  v-model="inventory.body.no_sipp"
                  placeholder="Silakan isi no. sip di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-card elevation="0" class="pa-2" flat>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="small_txt ma-0">
                  <b>Departemen</b>
                </p>
                <v-text-field
                  placeholder="Silakan isi departemen di sini"
                  v-model="inventory.body.departemen"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt ma-0"><b>Posisi</b></p>
                <v-text-field
                  v-model="inventory.body.jabatan"
                  placeholder="Silakan isi jabatan di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt ma-0"><b>Atasan</b></p>
                <v-text-field
                  v-model="inventory.body.atasan"
                  placeholder="Silakan isi atasan di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt ma-0">
                  <b>Posisi Atasan</b>
                </p>
                <v-text-field
                  v-model="inventory.body.jabatan_atasan"
                  placeholder="Silakan isi posisi atasan di sini"
                  color="#0D47A1"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3" class="pa-0">
          <v-card elevation="0" class="pa-2" flat>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="small_txt ma-0"><b>Email</b></p>
                <v-text-field
                  placeholder="Silakan isi email di sini"
                  v-model="inventory.body.email"
                  color="#0D47A1"
                  dense
                  :error="errors.email?true:false"
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
                <div v-if="errors.email">
                  <p
                    class="small_txt ma-0 red--text"
                    v-for="(err, idx) in errors.email"
                    :key="`mail-${idx}`"
                  >
                    - {{ err }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12">
                <p class="small_txt ma-0"><b>Kata Sandi</b></p>
                <v-text-field
                  v-model="inventory.body.password"
                  placeholder="Silakan isi kata sandi di sini"
                  color="#0D47A1"
                  :error="errors.password?true:false"
                  dense
                  hide-details
                  outlined
                  class="rounded-lg mb-2"
                ></v-text-field>
                <div v-if="errors.password">
                  <p
                    class="small_txt ma-0 red--text"
                    v-for="(err, idx) in errors.password"
                    :key="`pwd-${idx}`"
                  >
                    - {{ err }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-row v-else no-gutters>
      <v-col cols="12">
        <p class="small_txt ma-0 black_txt"><b>Nama</b></p>
        <v-text-field
          placeholder="Silakan isi nama di sini"
          v-model="inventory.body.nama"
          color="#0D47A1"
          dense
          hide-details
          outlined
          class="rounded-lg mb-2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="mt-1">
        <p class="small_txt ma-0 black_txt"><b>Email</b></p>
        <v-text-field
          v-model="inventory.body.email"
          placeholder="Silakan isi email di sini"
          color="#0D47A1"
          dense
          hide-details
          outlined
          class="rounded-lg mb-2"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mt-2">
      <v-btn
        v-if="step > 1 && inventory.type == 'c'"
        depressed
        class="text-capitalize"
        @click.stop="step--"
        dark
        outlined
        color="#BD9028"
      >
        <div style="color: black">Kembali</div>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="step < 3 && inventory.type == 'c'"
        depressed
        class="text-capitalize bg_color1"
        @click.stop="step++"
        dark
        >Lanjut</v-btn
      >
      <v-btn
        v-if="step == 3 || inventory.type != 'c'"
        depressed
        class="text-capitalize bg_color1"
        @click="createData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "createOfficer",
  props: ["inventory"],
  data() {
    return {
      role: "",
      step: 1,
      loading: false,
      field: {
        nama: "",
        username: "",
        telepon: "",
        nip: "",
        no_sipp: "",
        departemen: "",
        jabatan: "",
        tujuan: "",
        atasan: "",
        jabatan_atasan: "",
        email: "",
        password: "",
        repassword: "",
        avatar: null,
      },
      errors: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {},
  methods: {
    // tambah akun oficer dan kirim ke be
    createData() {
      let isEmpty = false;
      this.errors = {
        email: null,
        password: null,
      };
      for (const item in this.inventory.body) {
        if (!this.inventory.body[item]) {
          if (
            item == "username" ||
            item == "nama" ||
            item == "email" ||
            item == "password"
          ) {
            this.$toast.error(`${item} tidak boleh kosong!`);
            isEmpty = true;
          }
        }
      }
      if (!isEmpty) {
        this.loading = true;
        let data = {
          body: this.inventory.body,
          path: this.inventory.path,
        };
        this.$store
          .dispatch("officer/postApi", data)
          .then((data) => {
            this.loading = false;
            if (data.status == "success") {
              for (let i in this.field) {
                if(i != 'avatar'){
                  this.field[i] = "";
                }else{
                  this.field[i] = null;
                }
              }
              for (let i in this.inventory.body) {
                this.inventory.body[i] = "";
              }
              this.step = 1;
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "success",
              });
              this.$emit("success");
            } else {
              if(data.data.errors.email){
                this.errors.email = data.data.errors.email;
              }
              if(data.data.errors.password){
                this.errors.password = data.data.errors.password;
              }
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              if (key.length == 0) {
                this.$store.dispatch("notify", {
                  msg: data.message,
                  type: "error",
                });
              }
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            }
          })
          .catch((e) => {
            this.loading = false;
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: e.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
