<template>
  <div>
    <h4 class="default_txt text-center">
      {{
        selected.type == "all"
          ? "Semua isian pada seluruh form akan dihapus. Lanjutkan?"
          : "Isian pada form ini akan dihapus. Lanjutkan?"
      }}
    </h4>
    <div class="d-flex justify-center mt-2 py-3">
      <v-btn
        outlined
        class="text-capitalize mr-2"
        color="#BD9028"
        @click="$emit('close')"
        dark
      >
        <div style="color: black">Kembali</div>
      </v-btn>
      <v-btn
        depressed
        class="text-capitalize mr-1 bg_color1"
        @click="$emit('success')"
        dark
        >Lanjut</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "deleteFunction",
  props: ["selected"],

  data() {
    return {
      loading: false,
      success: false,
    };
  },
  methods: {},
};
</script>
