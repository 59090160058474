<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Tanggal Transaksi</b></p>
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selected.tgl_transaksi"
                prepend-inner-icon="mdi-calendar-range"
                readonly
                placeholder="Silakan isi tanggal transaksi di sini"
                hide-details
                dense
                v-on="on"
                outlined
                class="rounded-lg mb-2"
                @blur="count"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selected.tgl_transaksi"
              @input="closeDateMenu(selected.tgl_transaksi)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama Transaksi</b></p>
          <v-text-field
            placeholder="Silakan isi nama transaksi di sini"
            v-model="selected.nama_transaksi"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-lg mb-2"
            @blur="count"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Tag</b></p>
          <v-select
            placeholder="Pilih tag di sini"
            outlined
            hide-details
            :items="optProject"
            item-text="nama"
            item-value="id"
            v-model="selected.tag"
            dense
            class="rounded-lg mb-2"
            @blur="count"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Kategori Beban</b></p>
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                placeholder="Pilih kategori beban di sini"
                outlined
                hide-details
                :items="optBeban"
                item-text="nama_beban"
                item-value="id"
                v-model="selected.kategori_beban"
                dense
                class="rounded-lg mb-2"
                @blur="count"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center mb-2">
                <v-text-field
                  placeholder="Beban lainnya"
                  v-model="field.beban_lain"
                  color="#0D47A1"
                  outlined
                  dense
                  hide-details
                  class="rounded-lg mr-2"
                  @keyup.enter="createBeban"
                ></v-text-field>
                <v-btn
                  :disabled="loadingCustom"
                  depressed
                  class="text-capitalize bg_color1"
                  @click="createBeban"
                >
                  <h4 class="txt_white">Pilih</h4>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt">
            <b>Kuantitas</b>
          </p>
          <v-text-field
            placeholder="Silakan isi kuantitas di sini"
            v-model="selected.qty"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-lg mb-2"
            @blur="count"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt">
            <b>Cost</b>
          </p>
          <div
            class="rounded-lg d-flex align-center"
            style="height: 70%; border: 1px solid grey; overflow: hidden"
          >
            <v-card
              rounded="0"
              class="px-2 d-flex align-center justify-center"
              flat
              color="#052633"
              style="width: 50px; height: 100%"
            >
              <v-icon small color="white">Rp.</v-icon>
            </v-card>
            <currency-input
              @change="count"
              class_temp="rounded-lg"
              class="pa-2"
              style="height: 100%; width: 100%"
              placeholder="Silakan isi cost di sini"
              v-model="selected.cost"
              :options="{
                currency: 'IDR',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: false,
                hideGroupingSeparatorOnFocus: true,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: true,
                autoSign: true,
                useGrouping: true,
                accountingSign: false,
                precision: 0,
                locale: 'in',
                valueRange: { min: 0, max: 999999999999 },
              }"
            />
          </div>
        </v-col>
        <v-col cols="12" class="mt-2">
          <p class="small_txt ma-0 black_txt">
            <b>Total Biaya</b>
          </p>
          <div
            class="mb-2 rounded-lg d-flex align-center"
            style="height: 70%; border: 1px solid grey; overflow: hidden"
          >
            <v-card
              rounded="0"
              class="px-2 d-flex align-center justify-center"
              flat
              color="#052633"
              style="width: 50px; height: 100%"
            >
              <v-icon small color="white">Rp.</v-icon>
            </v-card>
            <currency-input
              :disabled="true"
              placeholder="Silakan isi total biaya di sini"
              v-model="selected.total_biaya"
              class_temp="rounded-lg mb-2"
              class="pa-2"
              style="height: 100%; width: 100%"
              :options="{
                currency: 'IDR',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: false,
                hideGroupingSeparatorOnFocus: true,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: true,
                autoSign: true,
                useGrouping: true,
                accountingSign: false,
                precision: 0,
                locale: 'in',
                valueRange: { min: 0, max: 999999999999 },
              }"
            />
          </div>
        </v-col>
        <v-col cols="12" class="mt-2">
          <p class="small_txt ma-0 black_txt"><b>Status</b></p>
          <v-radio-group
            v-model="selected.status"
            hide-details
            class="ma-0"
            dense
            row
          >
            <v-radio label="Sudah Dibayar" color="#052633" value="1"></v-radio>
            <v-radio label="Belum Dibayar" color="#052633" value="0"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex align-center justify-end mt-2">
      <v-btn
        outlined
        depressed
        color="#BD9028"
        class="text-capitalize mr-2"
        @click="$emit('close')"
        dark
      >
        <h4>Batal</h4>
      </v-btn>
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="updateData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CurrencyInput from "../../components/currencyInput/CurrencyInput.vue";
export default {
  components: { CurrencyInput },
  name: "updateExpenditure",
  props: ["selected"],
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      role: "",
      loading: true,
      loadingCustom: false,
      menuDate: false,
      optBeban: [],
      optProject: [],
      field: {
        beban_lain: "",
      },
    };
  },
  mounted() {
    this.count();
    // get data setelah ata profile ada
    let profile = setInterval(() => {
      if (this.profile) {
        this.fetchDataEvent();
        clearInterval(profile);
      }
    }, 300);
  },
  methods: {
    // get data
    fetchData(item) {
      this.loading = true;
      let data = {
        path: `sysadmin/expenditure/beban`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.optBeban = data;
          if (item) {
            this.selected.kategori_beban = item.id;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // get list event
    fetchDataEvent() {
      this.loading = true;
      let data = {
        path: `sysadmin/expenditure/tag`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          console.log(data);
          this.optProject = data;
          this.fetchData("");
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // tambah data expenditure/beban
    createBeban() {
      this.loadingCustom = true;
      let data = {
        body: {
          klien_id: this.profile.data.profile.klien_id,
          nama_beban: this.field.beban_lain,
          tipe: "custom",
        },
        path: `sysadmin/expenditure/beban`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.field.beban_lain = "";
          this.fetchData(data.data);
          this.loadingCustom = false;
        })
        .catch((e) => {
          this.loadingCustom = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    count() {
      this.selected.total_biaya = this.selected.qty * this.selected.cost;
    },
    closeDateMenu(item) {
      this.menuDate = false;
      this.selected.tgl_transaksi == ""
        ? this.$date(item).format("dddd, MMMM Do YYYY")
        : "";
    },

    // update expenditure
    updateData() {
      this.loading = true;
      let data = {
        body: {
          id: this.selected.id,
          tgl_transaksi: this.selected.tgl_transaksi,
          nama_transaksi: this.selected.nama_transaksi,
          kategori_beban: this.selected.kategori_beban,
          beban_lain: this.selected.beban_lain,
          qty: this.selected.qty,
          cost: this.selected.cost,
          total_biaya: this.selected.total_biaya,
          status: this.selected.status,
        },
        path: `sysadmin/expenditure/update`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loading = false;
          if (data.status == "success") {
            this.$emit("success");
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
  },
};
</script>
<style scoped></style>
