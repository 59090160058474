<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <div v-for="(item, n) in dataSet" :key="n">
            <div
              v-if="
                item.label != 'Kebutuhan Layanan' &&
                item.label != 'Jangka Waktu'
              "
              :class="n != 0 ? 'mt-3' : ''"
            >
              <h4>{{ item.label }}</h4>
              <v-text-field
                class="rounded-lg"
                outlined
                :placeholder="`Silakan isi ${item.label} disini`"
                v-model="item.value"
                color="#0D47A1"
                dense
                hide-details
              ></v-text-field>
            </div>
          </div>
        </v-col>
        <v-col cols="12" v-if="info_layanan">
          <div v-for="(item, n) in dataSet" :key="n">
            <div class="mt-3" v-if="item.label == 'Kebutuhan Layanan'">
              <div>
                <h4>{{ item.label }}</h4>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  :placeholder="`Silakan isi ${item.label} disini`"
                  v-model="item.value"
                  color="#0D47A1"
                  dense
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <div v-if="item.label == 'Jangka Waktu'" class="mt-3">
              <h4>{{ item.label }}</h4>
              <v-menu
                v-model="menuJangka_waktu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="item.value"
                    prepend-inner-icon="mdi-calendar-range"
                    readonly
                    placeholder="Silakan isi tanggal lahir di sini"
                    hide-details
                    dense
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="rounded-lg"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="item.value" range>
                  <div
                    style="width: 100%"
                    class="d-flex align-center justify-end mt-n7 mb-2 px-3"
                  >
                    <v-btn color="error" @click="menuJangka_waktu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      color="bg_color1"
                      dark
                      @click="closeDateMenu(item.value, n)"
                    >
                      OK
                    </v-btn>
                  </div>
                </v-date-picker>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="createData"
        dark
        >Simpan</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "createClient",
  computed: {
    ...mapState({
      code_client: (state) => state.code_client,
    }),
  },
  data() {
    return {
      loading: true,
      menuJangka_waktu: false,
      info_layanan: false,
      dataSet: null,
      klien_id: "",
    };
  },
  mounted() {
    let fetch = setInterval(() => {
      if (this.code_client) {
        this.fetchData();
        clearInterval(fetch);
      }
    }, 100);
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `registration/customer/${this.code_client}`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          let tempData = [];
          this.klien_id = data.klien.id;
          data.data.forEach((item) => {
            if (
              item.label == "Jangka Waktu" ||
              item.label == "Kebutuhan Layanan"
            ) {
              this.info_layanan = true;
            }
            tempData.push({
              attribute: item.attribute,
              label: item.label,
              deleted: item.deleted,
              id: item.id,
              status: item.status,
              type: item.type,
              tipe: item.tipe,
              value: "",
              validator: item.validator,
            });
          });
          this.dataSet = tempData;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    createData() {
      this.loading = true;
      let body = {
        klien_id: this.klien_id,
      };
      let custom_field = [];
      this.dataSet.forEach((item) => {
        if (item.label == "Jangka Waktu") {
          if (item.value.length > 1) {
            body[item.attribute] = `${this.$date(item.value[0]).format(
              "YYYY/MM/DD"
            )}-${this.$date(item.value[1]).format("YYYY/MM/DD")}`;
          } else {
            body[item.attribute] = item.value;
          }
        } else {
          if (item.attribute != null) {
            body[item.attribute] = item.value;
          } else {
            custom_field.push({
              [item.label]: item.value,
            });
          }
        }
      });
      body["custom_profile"] = JSON.stringify(custom_field);

      let data = {
        body: body,
        path: `sysadmin/registration/save`,
      };
      this.$store
        .dispatch("auth/registrasi_customer", data)
        .then((data) => {
          this.loading = false;
          if (data.code == 200) {
            this.dataSet.forEach((item) => {
              item.value = "";
            });
            this.$emit("success");
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else if (data.code == 201) {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
    closeDateMenu(item, idx) {
      this.menuJangka_waktu = false;
      this.dataSet[idx].value == ""
        ? this.$date(item).format("dddd, MMMM Do YYYY")
        : "";
    },
  },
};
</script>
