<template>
  <div>
    <div class="px-3">
      <div class="pa-3">
        <v-img src="../../assets/icon/Logo.png" contain class="logo"> </v-img>
      </div>
      <v-divider></v-divider>
      <v-list class="mb-10 px-2" dense>
        <v-list-item
          active-class="active_item_sidebar"
          class="mb-2 unactive_item_sidebar"
          link
          to="/"
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action active-class="active_item_sidebar" class="mb-2">
          <template v-slot:activator>
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-account-group-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="unactive_item_sidebar"
                >Manajemen Karyawan</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/list/employee"
          >
            <v-list-item-content>
              <v-list-item-title>Data Karyawan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/list/associate"
          >
            <v-list-item-content>
              <v-list-item-title>Associate</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/list/salary"
          >
            <v-list-item-content>
              <v-list-item-title>Gaji</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action active-class="active_item_sidebar" class="mb-2">
          <template v-slot:activator>
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-briefcase-variant-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="unactive_item_sidebar"
                >Operational Management</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/list/client"
          >
            <v-list-item-content>
              <v-list-item-title>Client</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/user/trash/admin_assessment"
          >
            <v-list-item-content>
              <v-list-item-title>Associate</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/user/trash/scholar"
          >
            <v-list-item-content>
              <v-list-item-title>Salary</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action active-class="active_item_sidebar" class="mb-2">
          <template v-slot:activator>
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-credit-card-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="unactive_item_sidebar"
                >Financial Management</v-list-item-title
              >
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/list/client"
          >
            <v-list-item-content>
              <v-list-item-title>Client</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/user/trash/admin_assessment"
          >
            <v-list-item-content>
              <v-list-item-title>Associate</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="active_item_sidebar "
            class="sub_menu custom_padding_sub my-2"
            link
            to="/user/trash/scholar"
          >
            <v-list-item-content>
              <v-list-item-title>Salary</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          active-class="active_item_sidebar"
          class="mb-2 unactive_item_sidebar"
          link
          @click="logout"
        >
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SidebarAdmin",
  props: ["drawer"],
  computed: {
    ...mapState({
      access: (state) => state.user.my_access,
      role: (state) => state.role,
    }),
    hider: {
      get: () => {
        return this.drawer;
      },
      set: () => {
        return this.drawer;
      },
    },
  },
  mounted() {},
  data() {
    return {
      sidebarMenu: true,
      changer: false,
    };
  },
  methods: {},
};
</script>

<style>
.v-list-item__icon {
  min-width: 10px !important;
  margin-left: 0 !important;
}
.v-list-item--link::before {
  background: none !important;
}
.custom_padding {
  padding-left: 50px !important;
}
.custom_padding_sub {
  padding-left: 10px !important;
}
.active_item_sidebar {
  background: #d2d2d27e;
  border-radius: 10px;
  color: #fff !important;
}
/* .unactive_item_sidebar {
} */

.bg-head {
  background: #e1e1e1;
}
.bg {
  background: #0e1614;
}
._avatar {
  object-fit: cover;
}

.text-small {
  font-size: small;
}
.sub_menu {
  margin-left: 35px;
}
</style>
