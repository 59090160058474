import axios from "axios";
import Cookies from "js-cookie";
// AUTH STUFF
const auth = {
  namespaced: true,
  state: {
    errMsg: "",
    response: "",
    config: {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        withCredentials: true,
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {
    login(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/auth/login`, data)
          .then((res) => {
            let data = res.data;
            if (data.code == 200) {
              Cookies.set("token", data.token, { expires: 7 });
              Cookies.set(
                "id",
                data.data.profile ? data.data.profile.id : data.data.id,
                { expires: 7 }
              );
              Cookies.set("role", data.data.role, { expires: 7 });
              Cookies.set(
                "code_client",
                data.data.klien ? data.data.klien.code_klien : null,
                {
                  expires: 7,
                }
              );
              Cookies.set("permissions", JSON.stringify(data.permission), {
                expires: 7,
              });
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    registrasi_customer(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/${data.path}`, data.body)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    ReqResetPassword(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/${data.path}`, data.body)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    resetPassword(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        axios
          .post(`${env}/api/${data.path}`, data.body)
          .then((res) => {
            let data = res.data;
            resolve(data);
          })
          .catch((error) => {
            let err = error.response ? error.response.data : error;
            reject(err);
          });
      });
    },
  },
};

export default auth;
