<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama Layanan</b></p>
          <v-text-field
            placeholder="Nama layanan"
            v-model="field.nama_layanan"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-lg mb-2"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        class="text-capitalize bg_color1"
        @click="createData"
        dark
        >Tambah</v-btn
      >
    </div>
    <v-overlay :absolute="true" color="white" :value="loading">
      <v-progress-circular
        indeterminate
        class="mr-2"
        color="#18174e"
      ></v-progress-circular>
      <b class="color_default">Loading...</b>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "createEventLayanan",

  data() {
    return {
      loading: false,
      field: {
        nama_layanan: "",
      },
    };
  },
  methods: {
    createData() {
      this.loading = true;
      let data = {
        body: {
          nama_layanan: this.field.nama_layanan,
        },
        path: `sysadmin/event/daftar-layanan/custom-admin`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.$emit("success");
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
    },
  },
};
</script>
