<template>
  <div>
    <div class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama Akun</b></p>
          <v-text-field
            v-model="selected.nama"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-md mb-2"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Nama Klien</b></p>
          <v-text-field
            v-model="selected.nama_klien"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-md mb-2"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Deskripsi</b></p>
          <v-textarea
            v-model="selected.description"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-md mb-2"
            disabled
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <p class="small_txt ma-0 black_txt"><b>Tanggal Aktivitas</b></p>
          <v-text-field
            :value="$date(selected.created_at).format('DD MMM YYYY hh:mm:ss')"
            color="#0D47A1"
            outlined
            dense
            hide-details
            class="rounded-md mb-2"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex align-center justify-end mt-2">
      <v-btn
        outlined
        depressed
        color="#BD9028"
        class="text-capitalize mr-2"
        @click="$emit('close')"
        dark
      >
        <h4>Oke</h4>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CurrencyInput from "../../components/currencyInput/CurrencyInput.vue";
export default {
  components: { CurrencyInput },
  props: ["selected"],
  name: "createExpenditure",
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style scoped>
.desc{
  width: 350px;
}
</style>
