<template>
  <div class="d-flex align-center temp_title">
    <div v-if="canBack">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="colorIcon"
            v-bind="attrs"
            v-on="on"
            class="mr-2"
            @click="`${$router.go(goBack ? goBack : -1)}`"
            icon
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </template>
        <span>Back</span>
      </v-tooltip>
    </div>
    <div v-if="!canBack" class="temp_bullet mr-2">
      <div class="dot"></div>
    </div>
    <h3 class="color_default">{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: "dotComponent",
  props: ["title", "canBack", "colorIcon", "goBack"],
};
</script>

<style scoped>
.dot {
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 50%;
}
</style>
