<template>
  <div>
    <div class="pa-5" style="width: 400px">
      <v-row no-gutters>
        <v-col cols="12">
          <h4 class="txt_color_default">Event</h4>
          <v-menu
            transition="slide-y-transition"
            bottom
            max-height="200px"
            rounded="lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                depressed
                block
                outlined
                v-bind="attrs"
                v-on="on"
                class="rounded-lg d-flex align-center justify-start text-capitalize"
              >
                <div class="small_txt" style="color: gray">
                  {{
                    selected.idx != null
                      ? selected.event[selected.idx].layanan +
                        " - " +
                        selected.event[selected.idx].customer
                      : "Silakan pilih event di sini"
                  }}
                </div>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="px-2"
                v-for="(item, n) in selected.event"
                :key="n"
              >
                <v-btn
                  block
                  depressed
                  :color="
                    selected.data.event_id == item.id ? '#E5E5E5' : 'white'
                  "
                  @click="changeEventId(item.id)"
                  class="d-flex align-center justify-start text-capitalize"
                >
                  {{ item.layanan + " - " + item.customer }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <h4 class="txt_color_default">Tugas</h4>
          <v-select
            placeholder="Silakan pilih tugas di sini"
            outlined
            hide-details
            :items="selected.event[selected.idx].timeline"
            item-text="step"
            item-value="step_id"
            dense
            v-model="selected.data.step_id"
            class="rounded-lg mb-2"
          ></v-select>
          <v-select
            filled
            disabled
            v-if="selected.idx == null"
            placeholder="Silakan pilih tugas di sini"
            outlined
            hide-details
            dense
            class="rounded-lg mb-2"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <div class="pr-2">
            <h4 class="txt_color_default">Status</h4>
            <v-select
              placeholder="Status tugas"
              outlined
              hide-details
              :items="optStatus"
              item-text="text"
              item-value="val"
              dense
              v-model="selected.data.status"
              class="rounded-lg mb-2"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="pl-2">
            <h4 class="txt_color_default">Prioritas</h4>
            <v-select
              placeholder="Prioritas tugas"
              outlined
              hide-details
              :items="optPrioritas"
              item-text="text"
              item-value="val"
              dense
              v-model="selected.data.prioritas"
              class="rounded-lg mb-2"
            ></v-select>
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            <h4 class="txt_color_default">Attachment</h4>
            <div v-if="selected.data.file != null" class="d-flex align-center">
              <div
                class="rounded-lg d-flex align-space-between"
                style="border: 0.05em solid #cdd0d1; flex-grow: 1"
              >
                <div
                  class="rounded-lg pa-2 d-flex align-center justify-center"
                  style="heigth: 100%; border-right: 0.05em solid #cdd0d1"
                >
                  <v-icon>mdi-file</v-icon>
                </div>
                <div
                  class="pa-2"
                  @click="toFile(selected.data.file)"
                  style="text-decoration-line: underline; cursor: pointer"
                >
                  {{ selected.data.file }}
                </div>
              </div>
              <v-btn
                class="ml-2"
                small
                depressed
                icon
                @click="selected.data.file = null"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-file-input
              v-if="!selected.data.file"
              v-model="file"
              placeholder="Silakan tambah file disini"
              outlined
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              dense
              hide-details
              class="rounded-lg mb-2"
            ></v-file-input>
          </div>
        </v-col>
        <v-col cols="12">
          <h4 class="txt_color_default">Karyawan</h4>
          <v-select
            filled
            readonly
            disabled
            placeholder="Silakan pilih karyawan di sini"
            outlined
            hide-details
            :items="selected.karyawan.data"
            item-text="nama"
            item-value="id"
            dense
            v-model="selected.data.karyawan_id"
            class="rounded-lg mb-2"
          ></v-select>
        </v-col>
      </v-row>
      <div class="d-flex align-center justify-end mt-4">
        <v-btn
          outlined
          depressed
          color="#BD9028"
          class="text-capitalize mr-2"
          @click="$emit('close')"
          dark
        >
          <h4>Batal</h4>
        </v-btn>
        <v-btn
          depressed
          class="text-capitalize bg_default"
          @click="updateData"
          dark
        >
          <h4 v-if="!loadingSave">Simpan</h4>
          <h4 v-if="loadingSave">Loading</h4>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {getFirestore, onSnapshot, collection, doc, setDoc, orderBy, query, where, or, getDoc, updateDoc} from "firebase/firestore";

export default {
  props: ["selected"],
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      loadingSave: false,
      file: null,
      optStatus: [
        {
          text: "Done",
          val: "done",
        },
        {
          text: "Ongoing",
          val: "ongoing",
        },
        {
          text: "Overdue",
          val: "overdue",
        },
      ],
      optPrioritas: [
        {
          text: "Low",
          val: "low",
        },
        {
          text: "Mid",
          val: "mid",
        },
        {
          text: "High",
          val: "high",
        },
      ],
      idSubThreadPerChat:'',
    };
  },
  mounted() {
    console.log(this.selected);
  },
  methods: {
    changeEventId(id) {
      this.selected.data.event_id = id;
      this.checkIdx(id);
    },
    checkIdx(idx) {
      for (let i = 0; i < this.selected.event.length; i++) {
        if (this.selected.event[i].id == idx) {
          this.selected.idx = i;
          break;
        }
      }
    },
    toFile(n) {
      window.open(n, "blank");
    },
    async updateData() {
      this.loadingSave = true;
      let success = false;
      let data = {
        body: [
          {
            id: this.selected.data.id,
          },
          {
            event_id: this.selected.data.event_id,
          },
          {
            klien_id: this.selected.data.klien.id,
          },
          {
            step_id: this.selected.data.step_id,
          },
          {
            status: this.selected.data.status,
          },
          {
            prioritas: this.selected.data.prioritas,
          },
          {
            karyawan_id: this.selected.data.karyawan_id,
          },
          {
            file: this.file,
          },
        ],
        path: `sysadmin/task-management/update`,
      };
      if (!this.file) {
        data.body.splice(7, 1);
      }
      await this.$store
        .dispatch("fileData/postApi", data)
        .then((data) => {
          this.loadingSave = false;
          if (data.status == "success") {
            success = true;
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else {
            var key = Object.keys(data.data.errors);
            var values = Object.values(data.data.errors);
            if (key.length == 0) {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
        })
        .catch((e) => {
          this.loadingSave = false;
          var key = Object.keys(e.data.errors);
          var values = Object.values(e.data.errors);
          if (key.length == 0) {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
          for (let i = 0; i < key.length; i++) {
            for (let j = 0; j < values[i].length; j++) {
              this.$toast.error(values[i][j]);
            }
          }
        });
      if(success){
        let step = '';
        if(this.selected.event[this.selected.idx]){
          step = this.selected.event[this.selected.idx].timeline.find(d => d.step_id === this.selected.data.step_id);
        }
        await this.randomIdChat();
        await setDoc(doc(getFirestore(), "notification/"+this.idSubThreadPerChat.toString()),{
          id: this.idSubThreadPerChat,
          sender: this.profile.data.profile.user_id,
          recipient: this.selected.data.karyawan.user_id,
          text: this.profile.data.profile.nama + ' menambah Anda di event ' + this.selected.event[this.selected.idx].layanan + ' - ' + this.selected.event[this.selected.idx].customer + ' dibagian ' + step.step,
          text_html: '<b>' + this.profile.data.profile.nama + '</b> menambah Anda di event ' + this.selected.event[this.selected.idx].layanan + ' - ' + this.selected.event[this.selected.idx].customer + ' dibagian ' + step.step,
          $url_page: '/admin/mk/task-management/',
          is_read: false,
          klien_id: this.selected.data.karyawan.klien_id,
          date: Date.now(),
        });

        this.$emit("success");
      }
    },
    async randomIdChat(){
      let id = Math.random().toString(13).slice(2);
      
      let docRef = doc(getFirestore(), "notification/"+id.toString());
      let docSnap = await getDoc(docRef);
      
      do {
          id = Math.random().toString(13).slice(2);
          docRef = doc(getFirestore(), "notification/"+id.toString())
          docSnap = await getDoc(docRef);
      } while (docSnap.exists()){
        this.idSubThreadPerChat = id;
      }
    },
  },
};
</script>

<style></style>
